<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">
            Шахматная олимпиада <span>{{ contest.name }}</span>
          </h1>
        </div>
      </div>
    </div>
    <hr class="mb-5">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white min-h-screen-75">
      <div v-if="waiting" class="pl-10">
        <vue-countdown :time="seconds" :interval="100" v-slot="{ days, hours, minutes, seconds }">
          <div class="mt-5">
            <p class="text-gray-600">Ожидайте подведения итогов ...</p>
          </div>
          <div class="text-6xl text-center flex w-full items-center mt-5">
            <div style="width: 6em" class="mr-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="дни">{{ days }}</div>
              <div class="font-mono text-xs leading-none mt-2 text-gray-500"><span>дни</span></div>
            </div>
            <div style="width: 6em" class="mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="часы">{{ hours }}</div>
              <div class="font-mono text-xs leading-none mt-2">часы</div>
            </div>
            <div style="width: 6em" class="mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="минуты">{{ minutes }}</div>
              <div class="font-mono text-xs leading-none mt-2">минуты</div>
            </div>
            <div style="width: 6em" class="mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="секунды">{{ seconds }}</div>
              <div class="font-mono text-xs leading-none mt-2">секунды</div>
            </div>
          </div>
          <div class="mt-5">
            <p class="text-blueGray-400 text-xs">Страница обновится автоматически</p>
          </div>
          <div class="mt-5">
            <p class="text-blueGray-700 text-xs">{{ contest.description }}</p>
          </div>
        </vue-countdown>
      </div>
      <div v-else>
        <ContestNavigation :contest="contest"/>
        <div v-if="result.member">
          <p style="padding: 0 0 1em 2em">
            Итоговое место: {{ result.place + 1 }}
          </p>
          <p style="padding: 0 0 1em 2em">Ваш результат:
            <stron>{{ result.points }} баллов</stron>
          </p>
          <p style="padding: 0 0 1em 2em">
            Время решения:
            <strong>{{ formatDuration(result.duration) }}</strong>
          </p>
          <hr>
          <Solution v-for="issue in getSortedIssues()" :key="issue.id" :issue="issue" :showTrace="true"></Solution>
        </div>
        <div v-else class="pl-10">
          <div class="mt-5">
            <p class="text-gray-600">Вы не принимали участие в олимпиаде.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import formatDuration from '@/formatters/duration'
import contestService from '@/services/contest.service'
import {doIndexIssues} from '@/utils'
import ContestNavigation from '@/views/my/contests/ContestNavigation'
import Solution from '@/views/my/trainer/Solution'
import {ref} from 'vue'
import {useRoute} from 'vue-router'

export default {
  name: 'ContestResult',
  components: {
    ContestNavigation,
    Solution
  },
  setup() {
    const route = useRoute()
    const contest = ref({})
    const result = ref({})
    const solutions = ref([])
    const waiting = ref(true)
    const issues = ref([])
    const seconds = ref(0)

    const getSortedIssues = () => {
      return issues.value.sort((issueA, issueB) => {
        return issueA.index - issueB.index
      })
    }

    const refresh = () => {
      contestService.getResults(route.params.id)
          .then(response => {
            contest.value = response.data.contest || {}
            result.value = response.data.result || {}
            solutions.value = response.data.solutions || []
            waiting.value = response.data.waiting
            seconds.value = response.data.seconds * 1000

            if (waiting.value) {
              setTimeout(refresh, seconds.value)
            }

            for (const solution of solutions.value) {
              solution.issue.task.analyze = solution.trace
              issues.value.push(solution.issue)
            }

            doIndexIssues(issues.value)
          })
    }

    refresh()

    return {
      contest,
      result,
      solutions,
      issues,
      seconds,
      waiting,
      getSortedIssues,
      formatDuration
    }
  }
}
</script>
