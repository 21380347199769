<template>
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 xl:mb-0 px-4">
      <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100">
        <div class="rounded bg-white mb-0 px-6 py-6">
          <div class="text-center flex justify-between">
            <h1 class="text-blueGray-700 text-xl font-bold pt-2">Тренажер</h1>
            <div class="pt-2">
              <vue_countdown v-if="state === 'RUNNING'" :time="time" v-slot="{ minutes, seconds }">
                <span class="pl-10">
                  <span
                      class="bg-emerald-200 text-emerald-600 text-lg font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
                    {{ minutes < 10 ? `0${minutes}` : minutes }}
                  </span>
                    :
                  <span
                      class="bg-emerald-200 text-emerald-600 text-lg font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
                    {{ seconds < 10 ? `0${seconds}` : seconds }}
                  </span>
                </span>
              </vue_countdown>
            </div>
            <button
                v-if="state === 'RUNNING'"
                class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                @click.prevent="finishTraining()"
                type="button">Завершить тренировку
            </button>
          </div>
        </div>

        <div v-if="state === 'FINISHED'" class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div class="flex content-center">
            <div class="flex flex-col flex-wrap content-center">
              <div class="flex flex-col text-center content-center mb-20 m-6 p-6 rounded">
                <h2 class="text-xl font-semibold text-gray-900 dark:text-white"
                    style="padding-top: 40px; padding-bottom: 30px;">
                  Ваш результат: {{ results.points }} баллов
                </h2>
              </div>
              <Solution v-for="issue in sortedIssues()" :key="issue.id" :issue="issue"></Solution>
            </div>
          </div>
        </div>

        <div v-if="state === 'WAIT'" class="flex-auto px-4 lg:px-10 py-10 pt-0" style="min-height: 400px;">
          <div v-if="showVideo" class="flex content-center items-center justify-center h-full mt-3 mb-3">
            <video-player
                src="/intro.mp4"
                controls
                width="800"
                loop="false"
                volume="1.0"/>
          </div>
          <div v-if="showVideo" class="flex content-center items-center justify-center h-full mt-3 mb-3">
            <p class="mt-3">Ваши предложения или замечания отправляйте на email: <a href="mailto:chesscontest"
                                                                                    class="text-red-500">chesscontest@ya.ru</a>
            </p>
          </div>
          <div class="flex content-center items-center justify-center h-full">
            <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                style="margin-top: 30px;"
                type="button"
                @click="onStart()">
              <i class="fas fa-exchange mr-2"></i> Начать тренировку
            </button>
          </div>
        </div>

        <div v-if="state === 'RUNNING'" class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div>
            <div class="flex flex-col flex-wrap content-center">
              <TasksSelector :current="currentIndex + 1" :max="training.issues.length" @selectTask="updateTask"/>
            </div>

            <div>
              <CheckmateIn1Move
                  v-if="currentIssue.type === issueType.CHECKMATE_IN_1_MOVE.type"
                  :issue="currentIssue"
                  :taskIndex="currentIssue.index"
                  :maxIndex="training.issues.length"
                  @addSolution="addCheckmateIn1MoveSolution"
                  @deleteSolution="deleteSolution"
                  @selectTask="updateTask"/>
              <DoomedKing
                  v-if="currentIssue.type === issueType.DOOMED_KING.type"
                  :issue="currentIssue"
                  :taskIndex="currentIssue.index"
                  :maxIndex="training.issues.length"
                  @addSolution="addSolution"
                  @deleteSolution="deleteSolution"
                  @selectTask="updateTask"/>
              <Labyrinth
                  v-if="currentIssue.type === issueType.LABYRINTH.type"
                  :issue="currentIssue"
                  :taskIndex="currentIssue.index"
                  :maxIndex="training.issues.length"
                  @addSolution="addLabyrinthSolution"
                  @selectTask="updateTask"/>
              <Giveaway
                  v-if="currentIssue.type === issueType.GIVEAWAY.type"
                  :issue="currentIssue"
                  :taskIndex="currentIssue.index"
                  :maxIndex="training.issues.length"
                  @addSolution="addGiveawaySolution"
                  @selectTask="updateTask"/>
              <CheckmateNetwork
                  v-if="currentIssue.type === issueType.CHECKMATE_NETWORK.type"
                  :issue="currentIssue"
                  :taskIndex="currentIssue.index"
                  :maxIndex="training.issues.length"
                  @addSolution="addCheckmateNetworkSolution"
                  @selectTask="updateTask"/>
              <TestQuestion
                  v-if="currentIssue.type === issueType.TEST.type"
                  :issue="currentIssue"
                  :taskIndex="currentIssue.index"
                  :maxIndex="training.issues.length"
                  @addSolution="updateTestSolution"
                  @selectTask="updateTask"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import issueType from '@/chess/issueType.js'
import contestService from '@/services/contest.service'
import {doIndexIssues} from '@/utils'
import CheckmateIn1Move from '@/views/my/trainer/CheckmateIn1Move.vue'
import CheckmateNetwork from '@/views/my/trainer/CheckmateNetwork.vue'
import DoomedKing from '@/views/my/trainer/DoomedKing.vue'
import Giveaway from '@/views/my/trainer/Giveaway.vue'
import Labyrinth from '@/views/my/trainer/Labyrinth.vue'
import Solution from '@/views/my/trainer/Solution'
import TasksSelector from '@/views/my/trainer/TasksSelector.vue'
import TestQuestion from '@/views/my/trainer/TestQuestion'
import vue_countdown from '@chenfengyuan/vue-countdown'
import {VideoPlayer} from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import {ref} from 'vue'


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Trainer',
  props: {
    showVideo: {
      type: Boolean,
      required: false
    }
  },
  components: {
    CheckmateIn1Move,
    Labyrinth,
    DoomedKing,
    CheckmateNetwork,
    Giveaway,
    TestQuestion,
    TasksSelector,
    vue_countdown,
    Solution,
    VideoPlayer
  },
  setup() {

    const state = ref('WAIT')
    const results = ref([])

    contestService.getTrainingResults().catch((response) => {
      results.value = response.data
    })

    contestService.getTrainingParams().catch((response) => {
      console.log('training params: ', response)
    })

    const time = ref(0)

    const formatDate = (dateString) => {
      const options = {year: 'numeric', month: 'numeric', day: 'numeric'}
      return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const currentIssue = ref(null)
    const currentIndex = ref(0)

    const addSolution = (solution) => {
      if (typeof solution === 'string') {
        currentIssue.value.task.solutions.push(solution)
      } else {
        currentIssue.value.task.solutions.push([...solution])
      }
    }

    const addCheckmateIn1MoveSolution = (solutions) => {
      currentIssue.value.task.solutions = solutions
    }

    const addLabyrinthSolution = (solutions) => {
      currentIssue.value.task.solutions = solutions
    }

    const addCheckmateNetworkSolution = (solutions) => {
      currentIssue.value.task.solutions = solutions
    }

    const coalesce = (value) => {
      if (value) {
        return value
      } else {
        return ''
      }
    }

    const addGiveawaySolution = (solutions) => {
      currentIssue.value.task.solutions = solutions.map(s => {
        const solution = []
        for (const move of s.moves) {
          if (move.length == 2) {
            solution.push([`${move[0].from}${move[0].to}${coalesce(move[0].promotion)}`, `${move[1].from}${move[1].to}${coalesce(move[1].promotion)}`])
          } else {
            solution.push([`${move[0].from}${move[0].to}${coalesce(move[0].promotion)}`])
          }
        }
        return solution
      })
    }

    const updateTestSolution = (answerId) => {
      currentIssue.value.task.solutions = [answerId]
    }

    const deleteSolution = (index) => {
      currentIssue.value.task.solutions =
          currentIssue.value.task.solutions.filter((sol, id) => id !== index)
    }

    const updateTask = async (index) => {
      index = Number.isInteger(index) ? index : currentIndex.value + 1
      currentIssue.value = getIssue(index)
      currentIndex.value = index
    }

    const getIssue = (id) => {
      return training.value.issues[id]
    }

    const training = ref({})
    const started = ref(false)

    const onStart = () => {
      if (started.value) {
        return
      }
      started.value = true

      contestService.startTraining()
          .then((response) => {
            training.value = response.data
            updateTask(currentIndex.value)
            state.value = 'RUNNING'
            time.value = Math.max(1000 * training.value.finishedAt - Date.now(), 0)
            training.value.issues.forEach(issue => {
              issue.name = issueType[issue.type].getName()
              issue.task.solutions = []
            })
            doIndexIssues(training.value.issues)

            training.value.issues = training.value.issues.sort((issueA, issueB) => {
              return issueA.index - issueB.index
            })
          })
          .catch((error) => {
            console.error(error)
            started.value = false
          })
    }

    const trainingResult = ref({})

    const finishTraining = () => {
      if (confirm('Завершить тренировку?')) {
        const solutions = {
          id: training.value.id,
          issues: training.value.issues.map(issue => {
            if (issue.type === 'LABYRINTH') {
              issue.task.solutions = issue.task.solutions.map(solution => {
                return solution.moves
                    .filter(move => move.color === 'w')
                    .map(move => {
                      return move.java
                    })
              })
            } else if (issue.type === 'CHECKMATE_IN_1_MOVE') {
              issue.task.solutions = issue.task.solutions.map(solution => {
                return `${solution.from}${solution.to}${solution.promotion && solution.promotion.length ? solution.promotion : ''}`
              })
            } else if (issue.type === 'CHECKMATE_NETWORK') {
              issue.task.solutions = issue.task.solutions.map(solution => {
                return solution.pieces.map(piece => piece.note)
              })
            }

            return issue
          })
        }
        contestService.finishTraining(solutions)
            .then((response) => {
              if (response.data && response.data.id) {
                results.value = response.data
                state.value = 'FINISHED'
                time.value = 0

                trainingResult.value = response.data
              }
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    contestService.getCurrentTraining()
        .then((response) => {
          if (response.data && response.data.id) {
            training.value = response.data
            updateTask(currentIndex.value)
            state.value = 'RUNNING'
            time.value = Math.max(1000 * training.value.finishedAt - Date.now(), 0)
            training.value.issues.forEach(issue => {
              issue.name = issueType[issue.type].getName()
              issue.task.solutions = []
            })
          }
        })
        .catch((error) => {
          console.error(error)
        })

    const sortedIssues = () => {
      if (trainingResult.value && trainingResult.value.issues) {
        return trainingResult.value.issues.sort((issueA, issueB) => {
          return issueA.index - issueB.index
        })
      }
      return []
    }

    return {
      time,
      results,
      training,
      currentIndex,
      currentIssue,
      state,
      trainingResult,
      issueType,
      sortedIssues,
      updateTask,
      addSolution,
      addGiveawaySolution,
      addLabyrinthSolution,
      addCheckmateIn1MoveSolution,
      addCheckmateNetworkSolution,
      deleteSolution,
      updateTestSolution,
      onStart,
      finishTraining,
      formatDate
    }
  }
}
</script>
