import CrudService from '@/services/crud.service.js'

class AccountService extends CrudService {

    constructor() {
        super('accounts')
    }

}

export default new AccountService()