<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <GeoNavigation/>
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Редактирование школы</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="update">
        <div v-if="errorMessage" class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                    <span class="inline-block align-middle mr-8">
                      <strong>Ошибка при сохранении школы:</strong> {{ errorMessage }}
                    </span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Название
              </label>
              <input
                  v-model="school.name"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Адрес
              </label>
              <input
                  v-model="school.address"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                ФИО Руководителя
              </label>
              <input
                  v-model="school.principal"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Сайт
              </label>
              <input
                  v-model="school.url"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Email
              </label>
              <input
                  v-model="school.email"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Телефон
              </label>
              <input
                  v-model="school.phone"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Регион
              </label>
              <Multiselect
                  v-model="school.region"
                  placeholder="Выберите регион ..."
                  :filter-results="false"
                  :min-chars="2"
                  :resolve-on-load="false"
                  :delay="0"
                  :canClear="false"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Регионы не найдены'"
                  ref="regionSelect"
                  :options="async function(query) {
                    return await fetchRegions(query)
                  }"/>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Населенный пункт
              </label>
              <Multiselect
                  v-model="school.city"
                  placeholder="Выберите населенный пункт ..."
                  :filter-results="false"
                  :min-chars="2"
                  :resolve-on-load="false"
                  :delay="0"
                  :canClear="false"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Населенные пункты не найдены'"
                  ref="citySelect"
                  :options="async function(query) {
                    return await fetchCities(query)
                  }"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Программа "Шахматы в школе"
              </label>
              <Toggle v-model="school.program" @change="onProgram()" :disabled="false"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex mx-auto mt-6 mb-20">
          <button type="submit"
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Сохранить
          </button>
          <button @click.prevent="cancel()"
                  class="bg-blueGray-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Отмена
          </button>
        </div>
      </form>

    </div>
  </div>
</template>
<script>

import schoolService from '@/services/api/school.service'
import cityService from '@/services/city.service'
import regionService from '@/services/region.service'
import {isNullOrEmpty} from '@/utils'
import GeoNavigation from '@/views/my/geo/GeoNavigation'
import Multiselect from '@vueform/multiselect'
import Toggle from '@vueform/toggle'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'SchoolEdit',
  components: {
    GeoNavigation, Toggle, Multiselect
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const errorMessage = ref(null)
    const school = ref({
      name: '',
      program: false,
      address: null,
      principal: null,
      url: null,
      email: null,
      phone: null,
      region: null,
      city: null
    })
    const regions = ref([])

    const regionSelect = ref(null)
    const citySelect = ref(null)

    const view = (id) => {
      schoolService.view(id)
          .then(response => {
            school.value = response.data
            if (response.data.region) {
              regionSelect.value.select({
                value: response.data.region.id,
                label: response.data.region.name
              })
            }

            if (response.data.city) {
              citySelect.value.select({
                value: response.data.city.id,
                label: response.data.city.name
              })
            }
          })
          .catch(error => console.error(error))
    }

    const fetchRegions = async (query) => {
      const response = await regionService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((region) => {
        return {
          value: region.id,
          label: region.name
        }
      })
    }

    const fetchCities = async (query) => {
      const params = {
        filter: query.toLowerCase(),
        size: 20
      }
      if (school.value.region) {
        params.region = school.value.region
      }
      const response = await cityService.search(params)

      return response.data.content.map((city) => {
        return {
          value: city.id,
          label: city.name
        }
      })
    }

    const cancel = async () => {
      await router.push(`/my/schools`)
    }

    const onProgram = () => {
      school.value.program = !!school.value.program
    }

    const update = async () => {
      if (isNullOrEmpty(school.value.name)) {
        errorMessage.value = 'Задайте название школы'
        return
      }
      if (!(school.value.region && school.value.region > 0)) {
        errorMessage.value = 'Задайте регион'
        return
      }
      if (!(school.value.city && school.value.city > 0)) {
        errorMessage.value = 'Задайте населенный пункт'
        return
      }

      errorMessage.value = null
      schoolService.update({
        id: school.value.id,
        name: school.value.name,
        program: school.value.program,
        address: school.value.address,
        principal: school.value.principal,
        url: school.value.url,
        email: school.value.email,
        phone: school.value.phone,
        region: {
          id: school.value.region
        },
        city: {
          id: school.value.city
        }
      })
          .then((response) => {
            router.push(`/my/schools?after_edit=${response.data.id}`)
          })
          .catch((error) => {
            console.error(error)
            errorMessage.value = error.toString()
          })
    }

    view(route.params.id)

    return {
      school,
      regions,
      errorMessage,
      regionSelect,
      citySelect,
      update,
      cancel,
      onProgram,
      fetchRegions,
      fetchCities
    }
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>