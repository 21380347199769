<template>
  <div>
    <navbar/>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
            class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            :style="`background-image: url('${registerBg2}');`"
        ></div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full mb-12 xl:mb-0 px-4">
            <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100">
              <div class="rounded bg-white mb-0 px-6 py-6">
                <div class="text-center flex justify-between">
                  <h1 class="text-blueGray-700 text-xl font-bold pt-2">Регистрация участника на олимпиаду
                    <span>{{ contest.name }}</span></h1>
                </div>
              </div>
              <div v-if="errorMessage" class="flex flex-wrap mt-6">
                <div class="w-full lg:w-12/12">
                  <div class="text-white px-6 py-4 border-0 relative mb-4 bg-red-500">
                    <span class="inline-block align-middle mr-8">
                      <strong>Ошибка:</strong> {{ errorMessage }}
                    </span>
                  </div>
                </div>
              </div>

              <div v-if="success" class="flex flex-wrap mt-6 mb-6">
                <div class="w-full lg:w-12/12">
                  <div class="text-white px-6 py-4 border-0 relative mb-4 bg-emerald-400">
                    <span class="inline-block align-middle mr-8">
                      Вы успешно зарегистрировались на олимпиаду, Вы можете войти в кабинет <a
                        href="/login">по ссылке</a>
                    </span>
                  </div>
                </div>
              </div>


              <!--              <div class="w-full p-5">-->
              <!--                <p></p>-->
              <!--              </div>-->
              <!--              <div class="w-full p-5">-->
              <!--                <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">-->
              <!--                  <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">-->
              <!--                    <a href="#" @click.prevent="mode = 'new'"-->
              <!--                       class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"-->
              <!--                       :class="[-->
              <!--                  mode === 'new'-->
              <!--                    ? 'text-white bg-emerald-600'-->
              <!--                    : 'text-emerald-600 bg-white',-->
              <!--                ]">Нет аккаунта</a>-->
              <!--                  </li>-->
              <!--                  <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">-->
              <!--                    <a href="#" @click.prevent="mode = 'exists'"-->
              <!--                       class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"-->
              <!--                       :class="[-->
              <!--                  mode === 'exists'-->
              <!--                    ? 'text-white bg-emerald-600'-->
              <!--                    : 'text-emerald-600 bg-white',-->
              <!--                ]">Есть аккаунт</a>-->
              <!--                  </li>-->
              <!--                </ul>-->
              <!--              </div>-->

              <!--              <div v-if="mode === 'exists'" class="flex-auto px-4 lg:px-10 py-10 pt-0">-->
              <!--                EXISTS-->
              <!--              </div>-->

              <div v-if="mode === 'new' && !success" class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form @submit.prevent="join">

                  <div class="flex flex-wrap mt-6">
                    <div class="w-full lg:w-12/12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold">
                          Имя пользователя
                        </label>
                        <span class="text-blueGray-400 text-sm">Используется для входа в кабинет</span>
                        <input
                            required="required"
                            v-model="account.username"
                            class="mb-6 mt-6 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            name="username"
                            type="text"
                            autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-wrap mb-6">
                    <div class="w-full lg:w-12/12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Пароль
                        </label>
                        <input
                            required="required"
                            v-model="account.password"
                            class="mb-6 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            type="password"
                            name="password"
                            autocomplete="off"
                        />
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Подтверждение пароля
                        </label>
                        <input
                            required="required"
                            v-model="account.confirmPassword"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            type="password"
                            name="passwordConfirm"
                            autocomplete="off"
                        />
                      </div>
                    </div>
                  </div>

                  <hr>

                  <!--                  <div class="flex flex-wrap mt-6 mb-6">-->
                  <!--                    <div class="w-full lg:w-12/12">-->
                  <!--                      <p></p>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <hr>-->
                  <!--                  <div class="flex flex-wrap mt-6 mb-6">-->
                  <!--                    <div class="w-full lg:w-12/12">-->
                  <!--                      <div class="relative w-full mb-3">-->
                  <!--                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">-->
                  <!--                          Email-->
                  <!--                        </label>-->
                  <!--                        <input-->
                  <!--                            v-model="account.email"-->
                  <!--                            type="text"-->
                  <!--                            name="email"-->
                  <!--                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div class="flex flex-wrap mt-6">-->
                  <!--                    <div class="w-full lg:w-12/12">-->
                  <!--                      <div class="relative w-full mb-3">-->
                  <!--                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">-->
                  <!--                          Телефон-->
                  <!--                        </label>-->
                  <!--                        <input-->
                  <!--                            v-model="account.phone"-->
                  <!--                            type="text"-->
                  <!--                            name="phone"-->
                  <!--                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"-->
                  <!--                        />-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full lg:w-12/12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Фамилия
                        </label>
                        <input
                            required="required"
                            v-model="account.lastName"
                            type="text"
                            name="lastName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-wrap mt-6">
                    <div class="w-full lg:w-12/username12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Имя
                        </label>
                        <input
                            required="required"
                            v-model="account.firstName"
                            type="text"
                            name="firstName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-wrap mt-6 mb-6">
                    <div class="w-full lg:w-12/12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Отчество
                        </label>
                        <input
                            v-model="account.patronymicName"
                            type="text"
                            name="patronymicName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="flex flex-wrap mt-6">
                    <div class="w-full lg:w-12/12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Пол
                        </label>
                        <Multiselect
                            v-model="account.gender"
                            placeholder="Выберите пол"
                            :options="genders"/>
                      </div>
                    </div>
                  </div>

                  <div class="flex flex-wrap mt-6">
                    <div class="w-full lg:w-12/12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Дата рождения
                        </label>
                        <VueDatePicker
                            v-model="account.birthdayAt"
                            locale="ru"
                            cancelText="Отмена"
                            selectText="Выбрать"
                            timezone="Europe/Moscow"
                            :enable-time-picker="false"
                            format="dd/MM/yyyy"/>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="flex flex-wrap mt-6">
                    <div class="w-full lg:w-12/12">
                      <div class="relative w-full mb-3">
                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Школа
                        </label>
                        <Multiselect
                            v-model="account.school"
                            placeholder="Выберите школу ..."
                            :filter-results="false"
                            :min-chars="2"
                            :resolve-on-load="false"
                            :delay="0"
                            :canClear="false"
                            :searchable="true"
                            :noOptionsText="'Введите несколько символов'"
                            :noResultsText="'Школы не найдены'"
                            ref="schoolSelect"
                            :options="async function(query) {
                            return await fetchSchools(query)
                          }"/>
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="flex mx-auto mt-6 mb-20">
                    <button type="submit"
                            class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                      Регистрация
                    </button>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
        <footer-small absolute/>
      </section>
    </main>
  </div>
</template>
<script>
import contestService from '@/services/contest.service'
import registerBg2 from '@/assets/img/register_bg_2.png'
import FooterSmall from '@/components/Footers/FooterSmall.vue'
import Navbar from '@/components/Navbars/AuthNavbar.vue'
import {ref} from 'vue'
import {useRoute} from 'vue-router/dist/vue-router'
import schoolService from '@/services/api/school.service'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'JoinPage',
  components: {
    Navbar,
    FooterSmall,
    Multiselect
  },
  setup() {
    const route = useRoute()

    const mode = ref('new')
    const contest = ref({})
    const roles = ref([
      {id: 'ADMIN', text: 'Администратор'},
      {id: 'MODERATOR', text: 'Модератор'},
      {id: 'CONTROLLER', text: 'Контроллер'},
      {id: 'MENTOR', text: 'Наставник'},
      {id: 'MEMBER', text: 'Участник'}
    ])
    const genders = ref([
      {
        value: 'MALE',
        label: 'Мужской'
      },
      {
        value: 'FEMALE',
        label: 'Женский'
      }
    ])
    const errorMessage = ref('')
    const success = ref(false)

    contestService.getByHash(route.params.id)
        .then((response) => {
          contest.value = response.data
          console.log(contest.value)
          if (!contest.value.id) {
            errorMessage.value = 'Олимпиада не найдена'
          }
        })
        .catch((e) => {
          console.error(e)
          errorMessage.value = e.message
        })

    const account = ref({
      firstName: '',
      lastName: '',
      patronymicName: '',
      gender: 'MALE',
      birthdayAt: '01/01/2010',
      username: '',
      password: '',
      confirmPassword: ''
    })

    const join = () => {
      if (!account.value.username) {
        errorMessage.value = 'Укажите имя пользователя'
        return
      }

      if (!account.value.firstName) {
        errorMessage.value = 'Укажите имя'
        return
      }

      if (!account.value.lastName) {
        errorMessage.value = 'Укажите фамилию'
        return
      }

      if (!account.value.password) {
        errorMessage.value = 'Укажите пароль'
        return
      }

      if (account.value.password !== account.value.confirmPassword) {
        errorMessage.value = 'Пароль и его подтверждение не совпадают'
        return
      }


      contestService.joinMember(route.params.id, {
        username: account.value.username,
        firstName: account.value.firstName,
        lastName: account.value.lastName,
        patronymicName: account.value.patronymicName,
        gender: account.value.gender,
        birthdayAt: account.value.birthdayAt,
        password: account.value.password,
        school: {id: account.value.school}
      })
          .then((response) => {
            console.log(response)
            success.value = true
          })
          .catch((error) => {
            console.error(error)
            errorMessage.value = error.response.data.message
          })

    }

    const fetchSchools = async (query) => {
      const response = await schoolService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((school) => {
        return {
          value: school.id,
          label: school.name
        }
      })
    }

    return {
      roles,
      genders,
      account,
      mode,
      contest,
      registerBg2,
      errorMessage,
      success,
      join,
      fetchSchools
    }
  }
}
</script>
