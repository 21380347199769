<template>
  <div>
    <a
        :id="`actionsMenu_${id}`"
        class="cursor-pointer text-blueGray-500 py-1 px-3"
        ref="btnDropdownRef"
    >
      <i class="fas fa-ellipsis-v"></i>
    </a>
    <div
        ref="popoverDropdownRef"
        class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
    >
      <a
          v-for="action in Object.keys(actions)" :key="action"
          @click="actions[action].function({id}, $emit)"
          class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
      >
        {{ actions[action].title }}
      </a>
    </div>
  </div>
</template>
<script>
import {createPopper} from '@popperjs/core'

export default {
  props: ['id', 'actions'],
  data() {
    return {
      dropdownPopoverShow: false,
    }
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault()
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false
      } else {
        this.dropdownPopoverShow = true
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: 'bottom-start',
        })
      }
    },
    closeDropdown: function (event) {
      try {
        // TODO pass id in props
        if (event.target.id === `actionsMenu_${this.id}` || event.target.parentElement.id === `actionsMenu_${this.id}`) {
          this.toggleDropdown(event)
        } else {
          this.dropdownPopoverShow = false
        }
      } catch (e) {
        // TODO
        console.error(e)
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown)
  }
}
</script>
