<template>
  <div class="flex flex-wrap">
    ISSUE VIEW
  </div>
</template>
<script>

export default {
  name: 'IssueView',
  components: {},
}
</script>
