<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Просмотр пользователя</h6>
        <div class="text-center flex justify-end">
          <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
              type="button"
              @click="$router.push(`/accounts/edit/${readUser.id.value}`)"
          >
            Редактировать
          </button>
          <button v-if="!user.disabled"
                  style="width: 150px"
                  class="bg-red-500 text-white active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                  type="button"
                  @click="block()"
          >
            Заблокировать
          </button>
          <button v-else
                  style="width: 150px"
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                  type="button"
                  @click="block()"
          >
            Разблокировать
          </button>
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <div class="flex flex-wrap mt-10 mb-6">
        <List :data="readUser"/>
        <div class="w-full px-4">
          <hr class="my-6 border-b-1 border-blueGray-300"/>
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Участия
          </h6>
          <div v-for="(value, key) in competes" :key="key"
               class="flex justify-start mb-3">
              <span
                  style="width: 100px;"
                  class="ml-3 text-blueGray-600 text-sm font-bold"
              >
                {{ value.date }}:
              </span>
            <a
                class="uppercase text-blueGray-600 align-middle">
              {{ value.value }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import AccountService from "@/services/account.service.js";
import {humanizeUser} from "@/utils.js";
import List from "@/components/Cards/List.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'AccountView',
  components: {List},
  props: ['id'],
  setup() {

    const user = ref({})
    const readUser = ref({})

    onMounted(async () => {
      // const res = await AccountService.view(props.id)
      const res = await AccountService.view(434)

      user.value = res.data.content[0];
      readUser.value = humanizeUser(user.value)

    });

    // const events = [{id, name, place, points},...]
    const events = [{
      id: 33,
      year: 2021,
      month: 'Апрель',
      title: 'White Rook',
      place: 3,
      points: 5
    }]

    const competes = events.map(compete => {
      return {
        date: `${compete.month} ${compete.year}`,
        value: `${compete.title} - место ${compete.place}`
      }
    })

    const block = async () => {
      user.value.disabled = !user.value.disabled
      await AccountService.update(user.value)
    }

    return {
      user,
      readUser,
      competes,
      block
    }
  }
};
</script>
