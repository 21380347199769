<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">

    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">Ваши олимпиады </h1>
        </div>
      </div>
    </div>
    <hr class="mb-5">
    <div class="w-full relative flex justify-start w-auto static block justify-start pb-4 px-4">
      <div class="w-full mb-12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 bg-white">
          <div class="block w-full overflow-x-auto">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
              <tr>
                <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Олимпиада
                </th>
                <th class="text-center px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Дата проведения
                </th>
                <th class="text-center px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Статус
                </th>
                <th class="text-center px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100">
                  Результат
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="contestsLoading">
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    colspan="4">
                  <span class="text-gray-500">Загрузка ...</span>
                </td>
              </tr>
              <tr v-if="!contestsLoading && contests.length === 0">
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
                    colspan="4">
                  <span class="text-gray-500">Вы не принимали участие в олимпиадах</span>
                </td>
              </tr>
              <tr v-for="contest in contests" :key="contest.id">
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                  {{ contest.name }}
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                  <span>{{ contest.startedAt }}</span>
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                  <span v-if="contest.status === 'ACTIVE'">
                    <i class="fas fa-circle text-orange-500 mr-2"></i> Проводится
                  </span>
                  <span v-if="contest.status === 'ARCHIVE'">
                    <i class="fas fa-circle text-emerald-500 mr-2"></i> Завершена
                  </span>
                </td>
                <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                  <router-link
                      v-if="contest.status === 'ACTIVE'"
                      :to="`/my/${contest.id}/start`"
                      v-slot="{ href, navigate }">
                    <a
                        :href="href"
                        @click="navigate"
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                      Принять участие
                    </a>
                  </router-link>
                  <router-link
                      v-if="contest.status === 'ARCHIVE'"
                      :to="`/my/${contest.id}/result`"
                      v-slot="{ href, navigate }">
                    <a
                        :href="href"
                        @click="navigate"
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded-full shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                      Посмотреть результат
                    </a>
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import formatDatetime from '@/formatters/datetime.js'
import contestService from '@/services/contest.service'
import {onMounted, ref} from 'vue'
import {useRouter} from 'vue-router/dist/vue-router'

export default {
  name: 'DashboardPage',
  components: {},
  setup() {
    const router = useRouter()
    onMounted(() => {
      const account = JSON.parse(localStorage.getItem('account'))
      if (!account || account.id === 0) {
        router.push({path: '/login'})
      }
    })

    const contests = ref([])
    const contestsLoading = ref(true)

    contestService.getMy()
        .then((response) => {
          contests.value = response.data
          contestsLoading.value = false
        })

    return {
      contests,
      contestsLoading,
      formatDatetime
    }
  }
}
</script>
