<template>
  <div class="flex flex-col text-center w-full mb-20 border-2 border-indigo-500 m-6 p-6 rounded">

    <div class="flex mx-auto mb-6">
      <div class="flex mx-auto overflow-hidden mt-6">
        <div class="relative mb-4" style="width: 800px;">
          <h3 class="mb-2 font-bold">Все решения мат в один ход:</h3>
          <ul>
            <li v-for="solution in currentTask.solutions" :key="solution">
              <span
                  class="text-xs font-semibold inline-block py-1 px-2 rounded text-emerald-600 bg-emerald-200 last:mr-0 mr-1">
                {{ formatSAN(solution) }}
              </span>
            </li>
            <li v-if="currentTask.solutions.length === 0">Решений не найдено</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="flex mx-auto mt-6">
      <div class="flex chess-editor white border-2 border-blueGray-600 rounded bg-gray-200">
        <div class="flex">
          <div @click="selectPointer('pointer')"
               :class="{'bg-gray-200': currentPointer !== 'pointer', 'bg-emerald-200': currentPointer === 'pointer'}"
               class="w-1/6 h-16 remove hover:bg-red-600">
            <img width="48" height="48" style="padding: 6px; margin: 8px;"
                 :src="images.pointer"/>
          </div>
          <div @click="selectPointer('remove')"
               :class="{'bg-gray-200': currentPointer !== 'remove', 'bg-emerald-200': currentPointer === 'remove'}"
               class="w-1/6 h-16 hand hover:bg-cyan-200">
            <img width="48" height="48" style="padding: 6px; margin: 8px;"
                 :src="images.remove"/>
          </div>
          <div @click="selectPointer('black.king')"
               :class="{'bg-gray-200': currentPointer !== 'black.king', 'bg-emerald-200': currentPointer === 'black.king'}"
               class="w-1/6 h-16 king hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.black.king"/>
          </div>
          <div @click="selectPointer('black.queen')"
               :class="{'bg-gray-200': currentPointer !== 'black.queen', 'bg-emerald-200': currentPointer === 'black.queen'}"
               class="w-1/6 h-16 queen hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.black.queen"/>
          </div>
          <div @click="selectPointer('black.rook')"
               :class="{'bg-gray-200': currentPointer !== 'black.rook', 'bg-emerald-200': currentPointer === 'black.rook'}"
               class="w-1/6 h-16 rook hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.black.rook"/>
          </div>
          <div @click="selectPointer('black.bishop')"
               :class="{'bg-gray-200': currentPointer !== 'black.bishop', 'bg-emerald-200': currentPointer === 'black.bishop'}"
               class="w-1/6 h-16 bishop hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.black.bishop"/>
          </div>
          <div @click="selectPointer('black.knight')"
               :class="{'bg-gray-200': currentPointer !== 'black.knight', 'bg-emerald-200': currentPointer === 'black.knight'}"
               class="w-1/6 h-16 knight hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.black.knight"/>
          </div>
          <div @click="selectPointer('black.pawn')"
               :class="{'bg-gray-200': currentPointer !== 'black.pawn', 'bg-emerald-200': currentPointer === 'black.pawn'}"
               class="w-1/6 h-16 pawn hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.black.pawn"/>
          </div>
        </div>
      </div>
    </div>

    <div class="flex mx-auto mt-6">
      <div className="blue merida">
        <div ref="board" className="cg-board-wrap" style="width: 512px; height: 512px;"></div>
      </div>
    </div>

    <div class="flex mx-auto mt-6">
      <div class="flex chess-editor white border-2 border-blueGray-600 rounded bg-gray-200">
        <div class="flex">
          <div @click="selectPointer('pointer')"
               :class="{'bg-gray-200': currentPointer !== 'pointer', 'bg-emerald-200': currentPointer === 'pointer'}"
               class="w-1/6 h-16 remove hover:bg-red-600">
            <img width="48" height="48" style="padding: 6px; margin: 8px;"
                 :src="images.pointer"/>
          </div>
          <div @click="selectPointer('remove')"
               :class="{'bg-gray-200': currentPointer !== 'remove', 'bg-emerald-200': currentPointer === 'remove'}"
               class="w-1/6 h-16 hand hover:bg-cyan-200">
            <img width="48" height="48" style="padding: 6px; margin: 8px;"
                 :src="images.remove"/>
          </div>
          <div @click="selectPointer('white.king')"
               :class="{'bg-gray-200': currentPointer !== 'white.king', 'bg-emerald-200': currentPointer === 'white.king'}"
               class="w-1/6 h-16 king hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.king"/>
          </div>
          <div @click="selectPointer('white.queen')"
               :class="{'bg-gray-200': currentPointer !== 'white.queen', 'bg-emerald-200': currentPointer === 'white.queen'}"
               class="w-1/6 h-16 queen hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.queen"/>
          </div>
          <div @click="selectPointer('white.rook')"
               :class="{'bg-gray-200': currentPointer !== 'white.rook', 'bg-emerald-200': currentPointer === 'white.rook'}"
               class="w-1/6 h-16 rook hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.rook"/>
          </div>
          <div @click="selectPointer('white.bishop')"
               :class="{'bg-gray-200': currentPointer !== 'white.bishop', 'bg-emerald-200': currentPointer === 'white.bishop'}"
               class="w-1/6 h-16 bishop hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.bishop"/>
          </div>
          <div @click="selectPointer('white.knight')"
               :class="{'bg-gray-200': currentPointer !== 'white.knight', 'bg-emerald-200': currentPointer === 'white.knight'}"
               class="w-1/6 h-16 knight hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.knight"/>
          </div>
          <div @click="selectPointer('white.pawn')"
               :class="{'bg-gray-200': currentPointer !== 'white.pawn', 'bg-emerald-200': currentPointer === 'white.pawn'}"
               class="w-1/6 h-16 pawn hover:bg-cyan-200">
            <img width="48" height="48" style="margin: 6px;"
                 :src="images.white.pawn"/>
          </div>
        </div>
      </div>
    </div>

    <div class="flex mx-auto overflow-hidden mt-6 mb-6">
      <button
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          @click.prevent="setStartPosition()"
          type="button">Начальная позиция
      </button>
      <button
          class="bg-red-400 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          @click.prevent="setEmptyPosition()"
          type="button">
        Очистить доску
      </button>
    </div>

    <div class="flex mx-auto mt-6">
      <div class="relative mb-4" style="width: 512px;">
        <label for="fen" class="leading-7 text-sm text-gray-600">FEN аннотация позиции:</label>
        <input @input="onFenChange()"
               @keydown.enter.prevent
               id="fen"
               type="text"
               v-model="currentTask.fen"
               class="text-center font-bold border border-2 border-red-500 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none w-full ease-linear transition-all duration-150">
        <span v-if="fenError.length > 0" class="text-sm text-red-500 mt-4"><strong>Ошибка в FEN аннотации:</strong> Invalid FEN: piece data does not contain 8 '/'-delimited rows</span>
      </div>
    </div>

  </div>
</template>

<script>
import images from '@/chess/images.js'
import formatSAN from '@/formatters/san.js'
import {Chess, validateFen} from 'chess.js'
import {Chessground} from 'chessground'
import {computed, onMounted, ref, watch} from 'vue'

export default {
  name: 'IssueEditorCheckmateIn1Piece',
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const board = ref(null)
    const cg = ref(null)
    const chessboard = ref(null)
    const currentPointer = ref('pointer')

    const currentTask = computed({
      get() {
        return props.task
      },
      set(newTask) {
        this.$emit('update:task', newTask)
      }
    })

    const fenError = ref('')

    const setPosition = (fen) => {
      if (fen) {
        cg.value.set({
          fen: `${fen} w - - 0 1`
        })
        currentTask.value.fen = cg.value.getFen()
        findSolutions()
        fenError.value = ''
      }
    }

    const setStartPosition = () => {
      setPosition('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR')
    }

    const setEmptyPosition = () => {
      setPosition('8/8/8/8/8/8/8/8')
    }

    const onFenChange = () => {
      fenError.value = ''
      const fenString = `${currentTask.value.fen} w KQkq - 0 1`

      const validation = validateFen(fenString)
      if (validation.ok) {
        cg.value.set({
          fen: fenString
        })
        currentTask.value.fen = cg.value.getFen()
        findSolutions()
      } else {
        fenError.value = validation.error
      }
    }

    const selectPointer = (type) => {
      switch (type) {
        case 'pointer':
        case 'remove':
        case 'white.king':
        case 'white.queen':
        case 'white.rook':
        case 'white.bishop':
        case 'white.knight':
        case 'white.pawn':
        case 'black.king':
        case 'black.queen':
        case 'black.rook':
        case 'black.bishop':
        case 'black.knight':
        case 'black.pawn':
          currentPointer.value = type
          break
        default:
          currentPointer.value = 'pointer'
          break
      }
    }

    const findSolutions = () => {
      try {
        const fenString = `${currentTask.value.fen} w KQkq - 0 1`
        const chess = new Chess(fenString)
        const newSolutions = []

        for (const move of chess.moves()) {
          const newChess = new Chess(fenString)
          newChess.move(move)
          if (newChess.isCheckmate()) {
            newSolutions.push(move)
          }
        }

        currentTask.value.solutions = newSolutions
      } catch (e) {
        console.error(e)

        currentTask.value.solutions = []
      }
    }

    const editorConfig = {
      coordinates: true,
      fen: currentTask.value.fen,
      events: {
        change: () => {
          currentTask.value.fen = cg.value.getFen()

          findSolutions()
        },
        select: (...args) => {
          switch (currentPointer.value) {
            case 'remove':
              cg.value.state.pieces.delete(args[0])
              break
            case 'white.king':
              cg.value.newPiece({
                color: 'white',
                role: 'king'
              }, args[0])
              break
            case 'white.queen':
              cg.value.newPiece({
                color: 'white',
                role: 'queen'
              }, args[0])
              break
            case 'white.rook':
              cg.value.newPiece({
                color: 'white',
                role: 'rook'
              }, args[0])
              break
            case 'white.bishop':
              cg.value.newPiece({
                color: 'white',
                role: 'bishop'
              }, args[0])
              break
            case 'white.knight':
              cg.value.newPiece({
                color: 'white',
                role: 'knight'
              }, args[0])
              break
            case 'white.pawn':
              cg.value.newPiece({
                color: 'white',
                role: 'pawn'
              }, args[0])
              break
            case 'black.king':
              cg.value.newPiece({
                color: 'black',
                role: 'king'
              }, args[0])
              break
            case 'black.queen':
              cg.value.newPiece({
                color: 'black',
                role: 'queen'
              }, args[0])
              break
            case 'black.rook':
              cg.value.newPiece({
                color: 'black',
                role: 'rook'
              }, args[0])
              break
            case 'black.bishop':
              cg.value.newPiece({
                color: 'black',
                role: 'bishop'
              }, args[0])
              break
            case 'black.knight':
              cg.value.newPiece({
                color: 'black',
                role: 'knight'
              }, args[0])
              break
            case 'black.pawn':
              cg.value.newPiece({
                color: 'black',
                role: 'pawn'
              }, args[0])
              break
            case 'pointer':
            default:
              break
          }
        }
      }
    }

    onMounted(() => {
      cg.value = Chessground(board.value, editorConfig)
      watch(
          () => props.task.fen,
          () => {
            setPosition(props.task.fen)
          },
          {immediate: true}
      )
    })

    return {
      images,
      currentTask,
      fenError,
      board,
      cg,
      editorConfig,
      chessboard,
      selectPointer,
      currentPointer,
      setStartPosition,
      setEmptyPosition,
      onFenChange,
      formatSAN
    }
  }
}
</script>

<style>
@import '../../assets/styles/chess.css';
</style>
