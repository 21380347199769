<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <GeoNavigation/>
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Редактирование региона</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="update">
        <div v-if="errorMessage" class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                    <span class="inline-block align-middle mr-8">
                      <strong>Ошибка при сохранении региона:</strong> {{ errorMessage }}
                    </span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Название
              </label>
              <input
                  v-model="region.name"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Программа "Шахматы в школе"
              </label>
              <Toggle v-model="region.program" @change="onProgram()" :disabled="false"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex mx-auto mt-6 mb-20">
          <button type="submit"
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Сохранить
          </button>
          <button @click.prevent="cancel()"
                  class="bg-blueGray-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Отмена
          </button>
        </div>
      </form>

    </div>
  </div>
</template>
<script>

import RegionService from '@/services/region.service.js'
import {isNullOrEmpty} from '@/utils'
import GeoNavigation from '@/views/my/geo/GeoNavigation'
import Toggle from '@vueform/toggle'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'RegionEdit',
  components: {
    GeoNavigation, Toggle
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const errorMessage = ref(null)
    const region = ref({
      program: false
    })

    const cancel = async () => {
      await router.push(`/my/regions`)
    }

    const view = (id) => {
      RegionService.view(id)
          .then(response => {
            region.value = response.data
          })
          .catch(error => console.error(error))
    }

    const onProgram = () => {
      region.value.program = !!region.value.program
      console.log(region.value.program)
    }

    const update = async () => {
      if (isNullOrEmpty(region.value.name)) {
        errorMessage.value = 'Задайте название региона'
        return
      }

      errorMessage.value = null
      RegionService.update({...region.value})
          .then((response) => {
            router.push(`/my/regions?after_edit=${response.data.id}`)
          })
          .catch((error) => {
            console.error(error)
            errorMessage.value = error.toString()
          })
    }

    view(route.params.id)

    return {
      region,
      errorMessage,
      update,
      cancel,
      onProgram
    }
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>