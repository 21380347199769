const russianPieces = {
    K: 'Кр', Q: 'Ф', R: 'Л', B: 'С', N: 'К', P: '',
}

const replaceLastChar = (str, newChar) => {
    return str.substring(0, str.length - 1) + newChar
}

export default (san, removeCheckmate) => {
    const regex = /^([KQRBNP]?)([a-h][1-8])?([-x]?)([a-h][1-8])([+#]?)([e.p]?[qr]?[QR]?)$/
    const match = san.match(regex)

    if (match) {
        const piece = match[1]
        const from = match[2]
        const capture = match[3]
        const to = match[4]
        const check = match[5]
        const promotion = match[6]

        let localized = ''

        if (piece) {
            localized += russianPieces[piece.toUpperCase()]
        }

        if (from) {
            localized += from
        }

        if (capture) {
            localized += capture === 'x' ? 'x' : '-'
        }

        localized += to

        if (check) {
            localized += check
        }

        if (promotion) {
            const promotedPiece = promotion.slice(-1)
            localized += '=' + russianPieces[promotedPiece.toUpperCase()]
        }

        if (removeCheckmate) {
            localized = localized
                .replace('#', '')
                .replace('+', '')
        }

        return localized
    } else {
        if (removeCheckmate) {
            san = san
                .replace('#', '')
                .replace('+', '')
        }

        if (san.endsWith('Q')) {
            return replaceLastChar(san, 'Ф')
        } else if (san.endsWith('R')) {
            return replaceLastChar(san, 'Л')
        } else if (san.endsWith('N')) {
            return replaceLastChar(san, 'К')
        } else if (san.endsWith('B')) {
            return replaceLastChar(san, 'С')
        } else {
            return san
        }
    }
}