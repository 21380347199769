<template>
  <div class="w-full p-5">
    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <router-link
            to="/my/regions"
            v-slot="{ href, navigate, isActive }">
          <a
              :href="href"
              @click="navigate"
              class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              :class="[
                  isActive
                    ? 'text-white bg-emerald-600'
                    : 'text-emerald-600 bg-white',
                ]">
            Регионы
          </a>
        </router-link>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <router-link
            to="/my/cities"
            v-slot="{ href, navigate, isActive }">
          <a
              :href="href"
              @click="navigate"
              class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              :class="[
                  isActive
                    ? 'text-white bg-emerald-600'
                    : 'text-emerald-600 bg-white',
                ]">
            Населенные пункты
          </a>
        </router-link>
      </li>
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <router-link
            to="/my/schools"
            v-slot="{ href, navigate, isActive }">
          <a
              :href="href"
              @click="navigate"
              class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              :class="[
                  isActive
                    ? 'text-white bg-emerald-600'
                    : 'text-emerald-600 bg-white',
                ]">
            Школы
          </a>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'GeoNavigation'
}
</script>
