import squares from '@/chess/squares.js'

const toDests = (chess) => {
    const dests = new Map()
    squares.forEach(s => {
        const ms = chess.moves({square: s, verbose: true})
        if (ms.length) dests.set(s, ms.map(m => m.to))
    })
    return dests
}

// доступные ходы для поддавков
// если есть хоть один ход где можно рубить
const toGiveawayDests = (chess) => {
    const dests = new Map()
    let captureMovesForWhite = []

    squares.forEach(s => {
        const ms = chess.moves({square: s, verbose: true})

        // Если текущая фигура белая и среди ходов есть рубящий
        if (chess.get(s) && chess.get(s).color === 'w') {
            captureMovesForWhite = captureMovesForWhite.concat(ms.filter(m => m.flags.includes('c')))
        }

        if (ms.length) dests.set(s, ms.map(m => m.to))
    })

    // Если есть рубящие ходы для белых
    if (captureMovesForWhite.length) {
        const captureDests = new Map()

        captureMovesForWhite.forEach(move => {
            if (!captureDests.has(move.from)) {
                captureDests.set(move.from, [])
            }

            captureDests.get(move.from).push(move.to)
        })

        return captureDests
    }

    return dests
}

const getPieceColor = (cg, key, capture) => {
    if (capture) {
        if (capture.color === 'white') {
            return 'black'
        } else {
            return 'white'
        }
    } else {
        const piece = cg.state.pieces.get(key)
        if (piece) {
            return piece.color
        } else {
            return false
        }
    }
}

const getCaptureMove = (chess) => {
    const moves = chess.moves({verbose: true})
    for (let i = 0; i < moves.length; i++) {
        if (moves[i].captured) {
            return moves[i]
        }
    }
    return false
}

const rewriteSolution = (solution, move) => {

    if (solution.moves.length >= move.index) {
        solution.moves.splice(move.index - 1)
    }
}

const getPieceLocation = (cg, piece) => {
    cg.state.pieces.forEach((key, _piece) => {
        if (_piece === piece) {
            return {piece, key}
        }
    })
}

export {
    toDests,
    toGiveawayDests,
    getPieceColor,
    rewriteSolution,
    getPieceLocation,
    getCaptureMove
}
