import pieces from "@/chess/pieces.js";

const getPieceSquares = (board) => {

    const boardPieces = Object.keys(board)
    const squares = []

    boardPieces.forEach(piece => {
        board[piece].forEach(key => {
            if (piece !== 'pawn') {
                squares.push({
                    key,
                    piece,
                    note: `${pieces[piece]}${key}`
                })
            } else {
                squares.push({
                    key,
                    piece,
                    note: `${key}`
                })
            }
        })
    })
    return squares
}

export {
    getPieceSquares
}
