<template>
  <div class="w-full p-5">
    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
      <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <router-link
            :to="`/my/${contest.id}/result`"
            v-slot="{ href, navigate, isActive }">
          <a
              :href="href"
              @click="navigate"
              class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              :class="[
                  isActive
                    ? 'text-white bg-emerald-600'
                    : 'text-emerald-600 bg-white',
                ]">
            Ваш результат
          </a>
        </router-link>
      </li>
      <li v-if="isMembers" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <router-link
            :to="`/my/${contest.id}/result/members`"
            v-slot="{ href, navigate, isActive }">
          <a
              :href="href"
              @click="navigate"
              class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              :class="[
                  isActive
                    ? 'text-white bg-emerald-600'
                    : 'text-emerald-600 bg-white',
                ]">
            Личное первенство
          </a>
        </router-link>
      </li>
      <li v-if="isTeams" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <router-link
            :to="`/my/${contest.id}/result/teams`"
            v-slot="{ href, navigate, isActive }">
          <a
              :href="href"
              @click="navigate"
              class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              :class="[
                  isActive
                    ? 'text-white bg-emerald-600'
                    : 'text-emerald-600 bg-white',
                ]">
            Командное первенство
          </a>
        </router-link>
      </li>
      <li v-if="isReports" class="-mb-px mr-2 last:mr-0 flex-auto text-center">
        <router-link
            :to="`/my/${contest.id}/result/reports`"
            v-slot="{ href, navigate, isActive }">
          <a
              :href="href"
              @click="navigate"
              class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
              :class="[
                  isActive
                    ? 'text-white bg-emerald-600'
                    : 'text-emerald-600 bg-white',
                ]">
            Отчеты
          </a>
        </router-link>
      </li>
    </ul>
    <hr>
  </div>
</template>
<script>

import {computed} from 'vue'

export default {
  name: 'ContestNavigation',
  props: ['contest'],
  setup(props) {
    const isMembers = computed(() => {
      return 'PERSONAL' === props.contest.type || 'MIXED' === props.contest.type
    })

    const isTeams = computed(() => {
      return 'TEAM' === props.contest.type || 'MIXED' === props.contest.type
    })

    const isReports = computed(() => {
      return true
    })

    return {
      isMembers,
      isTeams,
      isReports
    }
  }
}
</script>
