<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">
            Шахматная олимпиада <span>{{ contest.name }}</span>
          </h1>
        </div>
      </div>
    </div>
    <hr class="mb-5">
    <div class="flex flex-wrap mt-4 min-h-screen-75">
      <div v-if="status === 'LOADING'" class="pl-3">
        <p class="text-blueGray-400 text-xs">Загрузка данных олимпиады ...</p>
      </div>
      <div v-if="status === 'FINISHED'" class="pl-3">
        <p class="text-blueGray-400 text-xs">Олимпиада завершена, ожидайте отображения результатов ...</p>
      </div>
      <div v-if="contest.status === 'NOT_FOUND'">
        Олимпиада не найдена
      </div>
      <div v-if="status === 'WAITING'" class="pl-10">
        <vue-countdown :time="seconds" :interval="100" v-slot="{ days, hours, minutes, seconds }">
          <div class="mt-5">
            <p class="text-gray-600">Олимпиада начнется через:</p>
          </div>
          <div class="text-6xl text-center flex w-full items-center mt-5">
            <div style="width: 6em" class="mr-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="дни">{{ days }}</div>
              <div class="font-mono text-xs leading-none mt-2 text-gray-500"><span>дни</span></div>
            </div>
            <div style="width: 6em" class="mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="часы">{{ hours }}</div>
              <div class="font-mono text-xs leading-none mt-2">часы</div>
            </div>
            <div style="width: 6em" class="mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="минуты">{{ minutes }}</div>
              <div class="font-mono text-xs leading-none mt-2">минуты</div>
            </div>
            <div style="width: 6em" class="mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
              <div class="font-mono leading-none text-lg font-bold" x-text="секунды">{{ seconds }}</div>
              <div class="font-mono text-xs leading-none mt-2">секунды</div>
            </div>
          </div>
          <div class="mt-5">
            <p class="text-blueGray-400 text-xs">Страница обновится автоматически</p>
          </div>
          <div class="mt-5">
            <p class="text-blueGray-700 text-xs">{{ contest.description }}</p>
          </div>
        </vue-countdown>
      </div>
      <div class="w-full mb-12 xl:mb-0 " v-if="status === 'STARTED'">
        <div class="relative flex flex-col min-w-0 break-words w-full bg-blueGray-100">
          <div class="flex flex-col flex-wrap content-center">
            <vue-countdown :time="seconds" :interval="100" v-slot="{ days, hours, minutes, seconds }">
              <div class="text-6xl text-center flex w-full items-center mt-5">
                <div style="width: 6em" class="mr-1 p-5  rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
                  <div class="font-mono leading-none text-lg font-bold" x-text="дни">{{ days }}</div>
                  <div class="font-mono text-xs leading-none mt-2 text-gray-500"><span>дни</span></div>
                </div>
                <div style="width: 6em" class="w-24 mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
                  <div class="font-mono leading-none text-lg font-bold" x-text="часы">{{ hours }}</div>
                  <div class="font-mono text-xs leading-none mt-2">часы</div>
                </div>
                <div style="width: 6em" class="w-24 mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
                  <div class="font-mono leading-none text-lg font-bold" x-text="минуты">{{ minutes }}</div>
                  <div class="font-mono text-xs leading-none mt-2">минуты</div>
                </div>
                <div style="width: 6em" class="w-24 mx-1 p-5 rounded-lg" v-bind:class="{
                  'bg-red-200': minutes < 3 && hours === 0 && days === 0,
                  'bg-gray-200': minutes >= 3 || hours > 0 || days > 0
                }">
                  <div class="font-mono leading-none text-lg font-bold" x-text="секунды">{{ seconds }}</div>
                  <div class="font-mono text-xs leading-none mt-2">секунды</div>
                </div>
              </div>
            </vue-countdown>
          </div>
          <hr class="mt-5 mb-5"/>
          <div class="flex flex-col flex-wrap content-center mt-5">
            <TasksSelector :current="currentIndex + 1" :max="issues.length" @selectTask="updateTask"/>
          </div>

          <div>
            <CheckmateIn1Move
                v-if="currentIssue.type === issueType.CHECKMATE_IN_1_MOVE.type"
                :issue="currentIssue"
                :taskIndex="currentIssue.index"
                :maxIndex="issues.length"
                @addSolution="addCheckmateIn1MoveSolution"
                @deleteSolution="deleteSolution"
                @selectTask="updateTask"/>
            <DoomedKing
                v-if="currentIssue.type === issueType.DOOMED_KING.type"
                :issue="currentIssue"
                :taskIndex="currentIssue.index"
                :maxIndex="issues.length"
                @addSolution="addSolution"
                @deleteSolution="deleteSolution"
                @selectTask="updateTask"/>
            <Labyrinth
                v-if="currentIssue.type === issueType.LABYRINTH.type"
                :issue="currentIssue"
                :taskIndex="currentIssue.index"
                :maxIndex="issues.length"
                @addSolution="addLabyrinthSolution"
                @selectTask="updateTask"/>
            <Giveaway
                v-if="currentIssue.type === issueType.GIVEAWAY.type"
                :issue="currentIssue"
                :taskIndex="currentIssue.index"
                :maxIndex="issues.length"
                @addSolution="addGiveawaySolution"
                @selectTask="updateTask"/>
            <CheckmateNetwork
                v-if="currentIssue.type === issueType.CHECKMATE_NETWORK.type"
                :issue="currentIssue"
                :taskIndex="currentIssue.index"
                :maxIndex="issues.length"
                @addSolution="addCheckmateNetworkSolution"
                @selectTask="updateTask"/>
            <TestQuestion
                v-if="currentIssue.type === issueType.TEST.type"
                :issue="currentIssue"
                :taskIndex="currentIssue.index"
                :maxIndex="issues.length"
                @addSolution="updateTestSolution"
                @selectTask="updateTask"/>
          </div>
          <hr class="my-4">
          <div class="flex mx-auto overflow-hidden mt-6 mb-6"><!--v-if-->
            <button
                @click="doFinish"
                class="bg-red-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:bg-emerald-600 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button">Завершить олимпиаду
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import issueType from '@/chess/issueType'
import contestService from '@/services/contest.service'
import coalesce from '@/utils/coalesce'
import indexIssues from '@/utils/indexIssues'
import CheckmateIn1Move from '@/views/my/trainer/CheckmateIn1Move.vue'
import CheckmateNetwork from '@/views/my/trainer/CheckmateNetwork.vue'
import DoomedKing from '@/views/my/trainer/DoomedKing.vue'
import Giveaway from '@/views/my/trainer/Giveaway.vue'
import Labyrinth from '@/views/my/trainer/Labyrinth.vue'
import TasksSelector from '@/views/my/trainer/TasksSelector.vue'
import TestQuestion from '@/views/my/trainer/TestQuestion'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: 'ContestPage',
  components: {
    CheckmateIn1Move,
    CheckmateNetwork,
    DoomedKing,
    Giveaway,
    Labyrinth,
    TasksSelector,
    TestQuestion
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    const contest = ref({
      status: null
    })
    const status = ref('LOADING')
    const seconds = ref(0)
    const currentIssue = ref(null)
    const currentIndex = ref(0)
    const issues = ref([])

    const saveSolution = (issue) => {
      const task = issue.task
      if (issue.type === 'LABYRINTH') {
        task.solutions = task.solutions.map(solution => {
          return solution.moves
              .filter(move => move.color === 'w')
              .map(move => {
                return move.java
              })
        })
      } else if (issue.type === 'CHECKMATE_IN_1_MOVE') {
        task.solutions = Array.from(new Set(task.solutions.map(solution => {
          if (typeof solution === 'string') {
            return solution
          } else {
            return `${solution.from}${solution.to}${solution.promotion && solution.promotion.length ? solution.promotion : ''}`
          }
        })))
      } else if (issue.type === 'CHECKMATE_NETWORK') {
        task.solutions = task.solutions.map(solution => {
          return solution.pieces.map(piece => piece.note)
        })
      }

      contestService.saveSolution(contest.value.id, [{
        id: issue.id,
        task: task
      }]).then(response => {
        console.log(response.data)
      })
    }

    const addSolution = (solution) => {
      if (typeof solution === 'string') {
        currentIssue.value.task.solutions.push(solution)
      } else {
        currentIssue.value.task.solutions.push([...solution])
      }
      saveSolution(currentIssue.value)
    }

    const addCheckmateIn1MoveSolution = (solutions) => {
      currentIssue.value.task.solutions = solutions
      saveSolution(currentIssue.value)
    }

    const addLabyrinthSolution = (solutions) => {
      currentIssue.value.task.solutions = solutions
      saveSolution(currentIssue.value)
    }

    const addCheckmateNetworkSolution = (solutions) => {
      currentIssue.value.task.solutions = solutions
      saveSolution(currentIssue.value)
    }

    const addGiveawaySolution = (solutions) => {
      currentIssue.value.task.solutions = solutions.map(s => {
        const solution = []
        for (const move of s.moves) {
          if (move.length == 2) {
            solution.push([`${move[0].from}${move[0].to}${coalesce(move[0].promotion)}`, `${move[1].from}${move[1].to}${coalesce(move[1].promotion)}`])
          } else {
            solution.push([`${move[0].from}${move[0].to}${coalesce(move[0].promotion)}`])
          }
        }
        return solution
      })
      saveSolution(currentIssue.value)
    }

    const updateTestSolution = (answerId) => {
      currentIssue.value.task.solutions = [answerId]
      saveSolution(currentIssue.value)
    }

    const deleteSolution = (index) => {
      currentIssue.value.task.solutions =
          currentIssue.value.task.solutions.filter((sol, id) => id !== index)
      saveSolution(currentIssue.value)
    }

    const updateTask = async (index) => {
      index = Number.isInteger(index) ? index : currentIndex.value + 1
      currentIssue.value = issues.value.find(issue => issue.index === index)
      currentIndex.value = index
    }

    const finish = () => {

    }

    const refresh = () => {
      status.value = 'LOADING'

      contestService.start(contest.value.id)
          .then(response => {
            const contestStatus = response.data

            if (contestStatus.participate === 'FINISHED' || contestStatus.participate === 'TOO_LATE' || contestStatus.participate === 'NOT_MEMBER') {
              router.push(`/my/${contest.value.id}/result`)
            } else if (contestStatus.participate === 'NOT_FOUND') {
              router.push(`/my`)
            } else if (contestStatus.participate === 'STARTED') {
              if (contestStatus.finished) {
                router.push(`/my/${contest.value.id}/result`)
                return
              }

              status.value = contestStatus.participate
              seconds.value = contestStatus.seconds * 1000
              issues.value = contestStatus.issues

              issues.value.forEach(issue => {
                issue.name = issueType[issue.type].getName()
                if (issue.task && !issue.task.solutions) {
                  issue.task.solutions = []
                }
              })

              indexIssues(issues.value)
              updateTask(currentIndex.value)

              setTimeout(finish, seconds.value)
            } else { // WAITING
              status.value = contestStatus.participate
              seconds.value = contestStatus.seconds * 1000

              setTimeout(refresh, seconds.value)
            }
          })
    }

    contestService.view(route.params.id)
        .then(response => {
          contest.value = response.data

          if (contest.value.status === 'ACTIVE') {
            refresh()
          } else if (contest.value.status === 'ARCHIVE') {
            router.push(`/my/${contest.value.id}/result`)
          } else if (contest.value.status === 'DRAFT') {
            router.push(`/my`)
          }
        })

    const doFinish = () => {
      if (confirm('Сохранить решения и завершить олимпиаду?')) {
        contestService.finish(contest.value.id)
            .then(() => {
              router.push(`/my/${contest.value.id}/result`)
            })
      }
    }

    return {
      contest,
      status,
      seconds,
      currentIndex,
      currentIssue,
      issues,
      issueType,
      updateTask,
      addSolution,
      addGiveawaySolution,
      addLabyrinthSolution,
      addCheckmateIn1MoveSolution,
      addCheckmateNetworkSolution,
      deleteSolution,
      updateTestSolution,
      doFinish
    }
  }
}
</script>
