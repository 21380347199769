import {Chess} from "chess.js";


export default (fen, doCheck) => {

    //TODO проверить позицию короля и ладьи, если true
    if (!doCheck) {
        return `${fen} w - - 0 1`
    }

    const addCastle = (castling, key, castle) => {
        if (chess.get(key) && chess.get(key).type === 'r') {
            return castling + castle
        } else {
            return castling
        }
    }

    const checkSquare = (key, piece) => {
        if (chess.get(key) && chess.get(key).type === piece) {
            return true
        }
    }

    const chess = new Chess(fen)

    let castling = ''

    const castles = [{'e1': ['h1', 'a1']}, {'e8': ['h8', 'a8']}]

    const rooks = {
        a1: 'Q',
        h1: 'K',
        a8: 'q',
        h8: 'k'
    }

    if (checkSquare('e1', 'k')) {
        castles['e1'].forEach(key => {
            castling = addCastle(castling, key, rooks[key])
        })
    }

    if (checkSquare('e8', 'k')) {
        castles['e1'].forEach(key => {
            castling = addCastle(castling, key, rooks[key])
        })
    }

    return `${fen} w ${castling} - 0 1`

}
