const rolesLabels = {
    MEMBER: 'Участник',
    MENTOR: 'Наставник',
    CONTROLLER: 'Контроллер',
    MODERATOR: 'Модератор',
    ADMIN: 'Администратор'
}

export {
    rolesLabels
}
