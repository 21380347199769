<template>
  <div>
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 xl:mb-0 px-4">
        <div
            class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div class="rounded-t bg-white mb-0 px-6 py-6">
            <div class="text-center flex justify-between">
              <h3 class="text-blueGray-700 text-xl font-bold">Новая задача</h3>
            </div>
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form @submit.prevent="save">
              <div v-if="errorMessage" class="flex flex-wrap mt-6">
                <div class="w-full lg:w-12/12">
                  <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                    <span class="inline-block align-middle mr-8">
                      <strong>Ошибка при сохранении задачи:</strong> {{ errorMessage }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap mt-6">
                <div class="w-full lg:w-12/12">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Тип задачи
                    </label>
                    <select
                        @change="onChangeType()"
                        v-model="issue.type"
                        class="cursor-pointer border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
                      <option v-for="type in types"
                              :key="type.type"
                              :value="type.type"
                              :selected="issue.type === type.type">{{ type.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap mt-6">
                <div class="w-full lg:w-12/12">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Описание задачи
                    </label>
                    <textarea
                        v-model="issue.description"
                        type="text"
                        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        rows="4"/>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap mt-6 mb-6">
                <div class="w-full lg:w-12/12">
                  <div class="relative w-full mb-3">
                    <label
                        class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                      Тэги
                    </label>
                    <vue3-tags-input :tags="issue.tags" @on-tags-changed="onTagsChanged"
                                     placeholder="Тэгируйте задачи для быстрого поиска по ним"/>
                  </div>
                </div>
              </div>

              <issue-editor-checkmate-in1-piece
                  v-if="issue.type === issueType.CHECKMATE_IN_1_MOVE.type"
                  :task="issue.task"/>
              <issue-editor-checkmate-network
                  v-if="issue.type === issueType.CHECKMATE_NETWORK.type"
                  :task="issue.task"/>
              <issue-editor-doomed-king
                  v-if="issue.type === issueType.DOOMED_KING.type"
                  :task="issue.task"/>
              <issue-editor-giveaway
                  v-if="issue.type === issueType.GIVEAWAY.type"
                  :task="issue.task"/>
              <issue-editor-labyrinth
                  v-if="issue.type === issueType.LABYRINTH.type"
                  :task="issue.task"/>
              <issue-editor-test
                  v-if="issue.type === issueType.TEST.type"
                  :task="issue.task"/>

              <div class="flex mx-auto mt-6 mb-20">
                <button @click.prevent="save()"
                        class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                  Сохранить
                </button>
                <button @click.prevent="cancel()"
                        class="bg-blueGray-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import issueType from '@/chess/issueType'
import IssueEditorCheckmateIn1Piece from '@/components/Chess/IssueEditorCheckmateIn1Piece'
import IssueEditorCheckmateNetwork from '@/components/Chess/IssueEditorCheckmateNetwork'
import IssueEditorDoomedKing from '@/components/Chess/IssueEditorDoomedKing'
import IssueEditorGiveaway from '@/components/Chess/IssueEditorGiveaway'
import IssueEditorLabyrinth from '@/components/Chess/IssueEditorLabyrinth'
import IssueEditorTest from '@/components/Chess/IssueEditorTest'
import issueService from '@/services/issue.service'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import Vue3TagsInput from 'vue3-tags-input'

export default {
  name: 'IssueEdit',
  components: {
    IssueEditorCheckmateIn1Piece,
    IssueEditorCheckmateNetwork,
    IssueEditorDoomedKing,
    IssueEditorGiveaway,
    IssueEditorLabyrinth,
    IssueEditorTest,
    Vue3TagsInput
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const issue = ref({
      type: issueType.CHECKMATE_IN_1_MOVE.type,
      description: issueType.CHECKMATE_IN_1_MOVE.description,
      task: {
        fen: '8/8/8/8/8/8/8/8',
        solutions: [],
        maxMoves: 4,
        minFigures: 3,
        question: '',
        answers: [{
          text: '',
          correct: true
        }]
      }
    })
    const errorMessage = ref('')
    const types = ref(issueType.types)
    const onChangeType = () => {
      const type = issueType.valueOf(issue.value.type)

      issue.value.type = type.type
      issue.value.description = type.description
    }

    issueService.view(route.params.id)
        .then((response) => {
          issue.value = response.data
        })
        .catch((error) => {
          console.error(error)
        })

    const save = () => {
      if (confirm('Сохранить и закрыть задачу?')) {
        issueService.update({
          id: issue.value.id,
          type: issue.value.type,
          description: issue.value.description,
          tags: issue.value.tags,
          task: issue.value.task
        })
            .then(() => {
              router.push(`/my/issues`)
            })
            .catch((error) => {
              console.error(error)
              errorMessage.value = error.data
            })
      }
    }

    const cancel = () => {
      if (confirm('Выйти без сохранения?')) {
        router.push(`/my/issues`)
      }
    }

    const onTagsChanged = (tags) => {
      issue.value.tags = tags
    }

    return {
      issue,
      errorMessage,
      issueType,
      types,
      save,
      cancel,
      onChangeType,
      onTagsChanged
    }
  }
}
</script>