<template>
  <div>
    <input
        class="border-0 w-1/2 text-center px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        :value="value" @input="onInput" @keypress="checkInput" placeholder="Введите шахматную клетку"/>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  computed: {
    value() {
      return this.modelValue
    }
  },
  methods: {
    validate(cell) {
      const pattern = /^[a-hA-H][1-8]$/
      return pattern.test(cell)
    },
    checkInput(event) {
      const charCode = event.charCode
      const isBackspaceOrDelete = event.keyCode === 8 || event.keyCode === 46

      // Если это Backspace или Delete, то пропустим ввод без дополнительной проверки
      if (isBackspaceOrDelete) {
        return
      }

      const char = String.fromCharCode(charCode)
      const futureValue = this.value + char

      // Если символ или будущее значение недопустимы, предотвращаем ввод
      if (futureValue.length === 1 && !/^[a-hA-H]$/.test(char) || futureValue.length === 2 && !/^[a-hA-H][1-8]$/.test(futureValue)) {
        event.preventDefault()
      }
    },
    onInput(event) {
      const inputValue = event.target.value
      if (this.validate(inputValue) || inputValue === '') {
        this.$emit('update:modelValue', inputValue)
      } else {
        event.target.value = this.value  // восстанавливаем предыдущее значение
      }
    }
  }
}
</script>
