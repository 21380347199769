<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">Пользователи</h1>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-start w-auto static block justify-start pb-4 px-4">
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fas fa-search"></i>
          </span>
          <input
              v-model="searchQuery"
              type="text"
              @keyup="searchByName"
              placeholder="Фильтр по пользователям ..."
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
      </form>
      <button
          style="margin-left: auto"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
          type="button"
          @click="$router.push(`/my/accounts/new`)"
      >
        <i class="fas fa-plus mr-2"></i> Новый пользователь
      </button>
    </div>
    <table-list :columns="columns" :actions="actions" :data="users" @update="onBlock"/>
    <table-pagination :total="totalPages" @page-selected="loadPage"/>
  </div>
</template>
<script>

import TableList from '@/components/Tables/TableList.vue'
import TablePagination from '@/components/Tables/TablePagination.vue'
import {humanizeName, humanizeRoles} from '@/utils.js'
import {onMounted, ref, watchEffect} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import AccountService from '../../../services/account.service.js'

export default {
  name: 'AccountList',
  components: {TableList, TablePagination},
  setup() {

    const route = useRoute()
    const router = useRouter()
    const users = ref([])

    const getUserById = (id) => {
      return users.value.filter(user => user.id = id)[0]
    }

    const onDelete = (ref) => {
      const account = getUserById(ref.id)
      if (confirm(`Удалить пользователя ${account.username}?`)) {
        AccountService
            .delete(account.id)
            .then(() => {
              search(route.query)
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    const onBlock = (id) => {

      const update = (user) => {
        AccountService
            .update(user)
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.error(error)
            })
      }
      const user = getUserById(id)

      if (user.disabled) {
        user.disabled = false
        update(user)
      } else {
        if (confirm(`Заблокировать пользователя ${id}?`)) {
          user.disabled = true
          update(user)
        }
      }
    }

    const search = (query) => {
      AccountService.search(query)
          .then(response => {
            users.value = response.data.content
            totalPages.value = response.data.totalPages
          })
          .catch(error => console.error(error))
    }

    const tableProps = {
      actions: {
        edit: {
          title: 'Редактировать',
          function: (account) => {
            router.push(`/my/accounts/${account.id}/edit`)
          }
        },
        delete: {
          title: 'Удалить',
          function: onDelete
        }
      },
      columns: [
        {
          title: 'ID',
          value: (user) => {
            return user.id
          }
        },
        {
          title: 'Логин',
          value: (user) => {
            return user.username
          }
        },
        {
          title: 'ФИО',
          value: (user) => {
            return humanizeName(user)
          }
        },
        {
          title: 'Роль',
          value: (user) => {
            return humanizeRoles(user.roles)
          }
        }
      ]
    }

    onMounted(() => {
      watchEffect(() => {
        search(route.query)
      })
    })

    let searchTimeout = null
    const searchQuery = ref(route.query.filter || '')
    const searchByName = async () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(() => {
        router.push({
          path: '/my/accounts',
          query: {filter: searchQuery.value.toLowerCase().trim()}
        })
      }, 500)
    }
    const searchProps = {searchQuery, searchByName}

    const totalPages = ref(1)
    const loadPage = (page) => {
      search({page: page})
    }
    const paginationProps = {
      totalPages, loadPage
    }

    search(route.query)

    return {
      users,
      ...tableProps,
      ...searchProps,
      ...paginationProps,
      onBlock,
      onDelete
    }
  }

}
</script>
