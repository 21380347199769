import authHeader from '@/services/api/auth-header.js'
import http from '@/services/api/http'
import CrudService from '@/services/crud.service.js'

class AuthService extends CrudService {

    signIn(data) {
        return http.post('auth/login', data, {})
    }

    status() {
        return http.post('auth/status', {}, {
            headers: authHeader()
        })
    }
}

export default new AuthService()