const fenToChangeTurn = (fen) => {
    let tokens = fen.split(' ')
    tokens[1] = tokens[1] === 'w' ? 'b' : 'w'
    return tokens.join(' ')
}

const targetCapture = (move, target) => {
    if (move.captured && move.to.toLowerCase() === target.toLowerCase()) {
        return true
    }
}

const targetPosition = (move, target) => {
    if (move.to.toLowerCase() === target.toLowerCase()) {
        return true
    }
}

const targetCheck = (chess) => {
    return chess.inCheck()
}

export {
    fenToChangeTurn,
    targetCapture,
    targetCheck,
    targetPosition
}
