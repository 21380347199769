<template>
  <div class="w-full px-4">
    <div v-for="(value, key) in data" :key="key"
         class="flex justify-start mb-3">
            <span
                style="width: 100px;"
                class="ml-3 text-blueGray-600 text-sm font-bold"
            >
                {{ value.title }}
              </span>
      <a
          class="text-blueGray-600 align-middle">
        {{ value.value }}
      </a>
    </div>
  </div>
</template>
<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'List',
  props: ['data'],
  setup() {
  }
}
</script>
