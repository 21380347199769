<template>
  <div class="w-1/2 text-center ml-2 p-6 rounded">
    <div class="flex flex-col items-center" style="width: 506px; height: 100%">
      <a style="height: 100%; width: 400px; margin-right: auto"
         class="block border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
        <label class="block uppercase text-blueGray-600 pb-2 text-xs font-bold">
          РЕШЕНИЯ
        </label>
        <div v-if="solutions && solutions.length === 0" style="min-height: 27px">
          <span class="text-blueGray-400">Укажите решение на доске ...</span>
        </div>
        <div style="min-height: 27px">
            <span v-for="(solution, id) of solutions" :key="id"
                  :class="{ 'bg-gray-200 text-gray-600': !solution.successfull && !solution.failed,
                'bg-emerald-200 text-emerald-600': solution.successfull,
                'bg-red-200 text-red-600': solution.failed }"
                  class="text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 mb-1">
                <a @click="$emit('setSolution', id)">
                   {{ solutionsList[id] }}
                </a>
            <i v-if="!solution.successfull && !solution.failed" class="pl-3 fa-solid fa-trash"
               @click="$emit('deleteSolution', id)"></i>
          </span>
        </div>
        <div class="pt-2">
             <span>
           {{ currentSolution }}
             </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'
import {computed} from 'vue'

export default {
  name: 'SolutionsCard',
  props: ['type', 'solutions', 'solution'],
  setup(props) {

    const solutionsList = computed(() => {
      return printSolutions(props.type, props.solutions)
    })

    const currentSolution = computed(() => {
      return printSolution(props.type, props.solution)
    })

    const formatCheckmateIn1MoveSolution = (solution) => {
      if (typeof solution === 'string') {
        return solution
      } else if (solution.lan) {
        return solution.lan
      } else {
        return solution.san
      }
    }

    const printSolution = (type, solution) => {
      const formatters = {
        'CHECKMATE_IN_1_MOVE': () => formatCheckmateIn1MoveSolution(solution),
        'DOOMED_KING': () => solution.toString(),
        'CHECKMATE_NETWORK': () => solution.pieces.length ? solution.pieces.map(sol => formatSAN(sol.note)).join(', ') : ''
      }
      return formatters[type]()
    }

    const printSolutions = (type, solutions) => {
      return solutions.map(sol => printSolution(type, sol))
    }

    return {
      currentSolution,
      solutionsList,
      printSolution,
      formatSAN
    }
  }
}
</script>

<style>
</style>
