<template>
  <div class="flex flex-col text-center w-full mb-20 m-6 p-6 rounded">
    <div class="flex justify-center items-stretch w-full">
      <div class="w-1/2">
        <a style="height: 100%; width: 400px; margin-left: auto"
           class="block border-0 px-3 py-3 mb-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
          <label class="block uppercase text-blueGray-600 text-xs font-bold my-2">
            {{ issue.name }}
          </label>
          <span>
           {{ issue.description }}
        </span>
        </a>
      </div>
      <giveaway-solutions :solutions="solutions" :solution="currentSolution"
                          @setMove="setMove"/>
    </div>

    <div class="flex mx-auto mt-6">
      <div className="blue merida" style="position: relative;">
        <div ref="board" className="cg-board-wrap" style="width: 512px; height: 512px;"></div>
        <promotion ref="promotion" @piece-selected="onPromotion"></promotion>
      </div>
    </div>

    <div v-if="hasSuccessfullSolution()" class="flex mx-auto overflow-hidden mt-6 mb-6">
      <span class="text-white bg-emerald-500 font-semibold py-3 px-6">Задача решена!</span>
    </div>

    <div v-if="showFailed" class="flex mx-auto overflow-hidden mt-6 mb-6">
      <span class="text-white bg-red-500 font-semibold p-3">Указано неправильное решение, попробуйте еще раз!</span>
    </div>

    <div class="flex mx-auto overflow-hidden mt-6 mb-6">
      <button v-if="taskIndex > 0"
              class="bg-gray-200 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
              @click.prevent="prevIssue()"
              type="button">Предыдущая задача
      </button>
      <button
          v-if="!hasSuccessfullSolution()"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
          @click.prevent="resetPosition()"
          type="button">Начальная позиция
      </button>
      <button v-if="taskIndex != maxIndex - 1"
              class="bg-gray-200 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ml-3 mb-1 ease-linear transition-all duration-150"
              @click.prevent="nextIssue()"
              type="button">Следующая задача
      </button>
    </div>
  </div>
</template>

<script>
import {Chess} from '@/chess/chess.js.patched/src/chess.ts'
import {toGiveawayDests} from '@/chess/trainer/utils'
import formatFEN from '@/formatters/fen.js'
import GiveawaySolutions from '@/views/my/trainer/GiveawaySolutions.vue'
import Promotion from '@/views/my/trainer/Promotion'
import {Chessground} from 'chessground'
import {onMounted, ref} from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Giveaway',
  components: {Promotion, GiveawaySolutions},
  props: {
    issue: {
      type: Object,
      required: true
    },
    taskIndex: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {
    const board = ref(null)
    const cg = ref(null)
    const promotion = ref(null)
    const solutions = ref(props.issue.task.solutions || [])
    const currentSolution = ref({moves: []})
    const showFailed = ref(false)

    // настраиваем движок
    const bestMoves = []
    var stockfish = new Worker('/js/stockfish.wasm.js')


    setTimeout(async () => {
      try {
        stockfish.addEventListener('message', (event) => {
          console.log('stockfish event: ', event)
          let message = event.data
          if (message.startsWith('bestmove')) {
            if (bestMoves.length === 0) {
              let parts = message.split(' ')
              let bestMove = {
                from: parts[1].substring(0, 2),
                to: parts[1].substring(2, 4)
              }

              bestMoves.push(bestMove)
            }
          }
        })
        stockfish.postMessage('uci')
        stockfish.postMessage('setoption name UCI_Variant value giveaway')

      } catch (e) {
        console.log('stockfish error: ', e)
      }
    }, 1)

    const getBestMove = async (fen) => {
      return new Promise((resolve) => {

        stockfish.postMessage(`position fen ${fen}`)
        stockfish.postMessage('go depth 5 movetime 1000')

        const intervalId = setInterval(() => {
          if (bestMoves.length > 0) {
            clearInterval(intervalId)
            resolve(bestMoves.shift())
            // engine.stop()
          }
        }, 1)
      })
    }


    const startPosition = {
      fen: formatFEN(props.issue.task.fen),
      maxMoves: props.issue.task.maxMoves
    }

    console.log(startPosition.fen)

    let game = new Chess(startPosition.fen)
    const config = {
      fen: startPosition.fen,
      viewOnly: false,
      turnColor: 'white',
      movable: {
        free: false,
        color: 'white',
        dests: toGiveawayDests(game),
        events: {
          after: makeAutoCaptureMove
        }
      }
    }

    const hasSuccessfullSolution = () => {
      return solutions.value.find(s => s.successfull)
    }

    const hasWhitePieces = (chess) => {
      const board = chess.board()
      for (const row of board) {
        for (const square of row) {
          if (square && square.color === 'w') {
            return true
          }
        }
      }
      return false
    }

    const hasBlackPieces = (chess) => {
      const board = chess.board()
      for (const row of board) {
        for (const square of row) {
          if (square && square.color === 'b') {
            return true
          }
        }
      }
      return false
    }

    const setMove = () => {

    }

    const stopPosition = () => {
      cg.value.stop()
      currentSolution.value = {moves: []}
    }

    const resetPosition = () => {
      game = new Chess(startPosition.fen)
      cg.value = Chessground(board.value, config)
      currentSolution.value = {moves: []}
    }

    const isSameMove = (m1, m2) => {
      if (m1 && m2) {
        if (m1.promotion) {
          return m1.from === m2.from && m1.to === m2.to && m1.promotion === m2.promotion
        } else {
          return m1.from === m2.from && m1.to === m2.to
        }
      } else if (m1 || m2) {
        return false
      } else {
        return true
      }
    }

    const isSameMoves = (m1, m2) => {
      return isSameMove(m1[0], m2[0]) && isSameMove(m1[1], m2[1])
    }

    const isSameSolution = (s1, s2) => {
      if (s1.moves && s2.moves) {
        if (s1.moves.length === s2.moves.length) {
          console.log('s1.moves.length: ', s1.moves.length)
          for (let i = 0; i < s1.moves.length; i++) {
            if (!isSameMoves(s1.moves[i], s2.moves[i])) {
              return false
            }
          }
          return true
        }
      }
      return false
    }

    const evaluation = (blackMovesIsEmpty = false) => {
      if (blackMovesIsEmpty) {
        if (!hasBlackPieces(game)) {
          currentSolution.value.failed = true
        } else if (hasWhitePieces(game)) {
          currentSolution.value.failed = true
        } else {
          currentSolution.value.successfull = true
        }
      } else {
        if (currentSolution.value.moves.length === startPosition.maxMoves) {
          if (!hasBlackPieces(game)) {
            currentSolution.value.failed = true
          } else if (hasWhitePieces(game)) {
            currentSolution.value.failed = true
          } else {
            currentSolution.value.successfull = true
          }
        } else {
          if (!hasBlackPieces(game)) {
            currentSolution.value.failed = true
          } else if (!hasWhitePieces(game)) {
            currentSolution.value.successfull = true
          }
        }
      }

      if (currentSolution.value.successfull) {
        if (solutions.value.some((s) => isSameSolution(s, currentSolution.value))) {
          resetPosition()
        } else {
          solutions.value.push(currentSolution.value)
          console.log('solutions', solutions.value)
          emit('addSolution', solutions.value)

          stopPosition()
        }

      } else if (currentSolution.value.failed) {
        if (solutions.value.some((s) => isSameSolution(s, currentSolution.value))) {
          resetPosition()
        } else {
          solutions.value.push(currentSolution.value)
          console.log('solutions', solutions.value)
          emit('addSolution', solutions.value)
          showFailed.value = true

          setTimeout(() => {
            showFailed.value = false
          }, 5000)

          resetPosition()
        }
      }
    }

    const getRandomMove = (moves) => {
      return moves[Math.floor(Math.random() * moves.length)]
    }

    onMounted(() => {
      cg.value = Chessground(board.value, config)
      if (hasSuccessfullSolution()) {
        cg.value.stop()
      }
    })

    const doBlackMove = (move) => {
      const currentMove = game.move({from: move.from, to: move.to, promotion: 'q'})
      cg.value.set({
        fen: game.fen(),
        lastMove: [move.from, move.to],
        turnColor: game.turn() === 'w' ? 'white' : 'black',
        movable: {
          dests: toGiveawayDests(game)
        }
      })
      return currentMove
    }

    const doWhiteAndBlackMoves = (from, to, promotion = 'q') => {

      showFailed.value = false
      const whiteMove = game.move({from, to, promotion})
      let blackMove = null

      setTimeout(async () => {
        const blackMoves = game.moves({verbose: true})
        let blackMovesIsEmpty = false

        if (blackMoves.length === 0) {
          blackMovesIsEmpty = true
        } else if (blackMoves.length === 1) {
          // единственно возможный ход - делаем его
          blackMove = doBlackMove(blackMoves[0])
        } else {
          // отфильтровываем все ходы в которых черные могут срубить
          const capturedMoves = blackMoves.filter(m => m.captured)
          if (capturedMoves.length === 0) {
            // делаем лучший ход от движка
            let bestMove = await getBestMove(cg.value.getFen() + ' b - - 0 1')
            if (!bestMove) {
              bestMove = getRandomMove(blackMoves)
            } else {
              console.log('bestMove: ', bestMove)
            }

            blackMove = doBlackMove(bestMove)
          } else if (capturedMoves.length === 1) {
            blackMove = doBlackMove(capturedMoves[0])
          } else {
            // делаем лучший ход от движка
            let bestMove = await getBestMove(cg.value.getFen() + ' b - - 0 1')
            if (!bestMove) {
              bestMove = getRandomMove(blackMoves)
            } else {
              console.log('bestMove: ', bestMove)
            }

            blackMove = doBlackMove(bestMove)
          }
        }

        if (blackMove) {
          currentSolution.value.moves.push([whiteMove, blackMove])
        } else {
          currentSolution.value.moves.push([whiteMove])
        }

        evaluation(blackMovesIsEmpty)

      }, 300)
    }

    function makeAutoCaptureMove(orig, dest) {
      if (cg.value.state.pieces.get(dest).role === 'pawn' && (dest[1] === '1' || dest[1] === '8')) {
        promotion.value.show(orig, dest)
      } else {
        doWhiteAndBlackMoves(orig, dest)
      }
    }

    const transformPromotion = (piece) => {
      switch (piece) {
        case 'queen':
          return 'q'
        case 'knight':
          return 'n'
        case 'bishop':
          return 'b'
        case 'rook':
          return 'r'
      }
    }

    const onPromotion = (params) => {
      const {orig, dest} = params

      const piecesDiff = new Map()
      piecesDiff.set(dest, {
        color: 'white',
        role: params.piece,
        promoted: true
      })
      cg.value.setPieces(piecesDiff)

      doWhiteAndBlackMoves(orig, dest, transformPromotion(params.piece))
    }

    const prevIssue = () => {
      emit('selectTask', props.taskIndex - 1)
    }

    const nextIssue = () => {
      emit('selectTask', props.taskIndex + 1)
    }

    return {
      promotion,
      board,
      cg,
      solutions,
      currentSolution,
      showFailed,
      onPromotion,
      setMove,
      resetPosition,
      prevIssue,
      nextIssue,
      hasSuccessfullSolution
    }
  }
}
</script>

<style>
@import '../../../assets/styles/chess.css';
</style>
