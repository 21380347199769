<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Новая команда</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="update">
        <div v-if="errorMessage" class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                    <span class="inline-block align-middle mr-8">
                      <strong>Ошибка при сохранении команды:</strong> {{ errorMessage }}
                    </span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Название
              </label>
              <input
                  v-model="team.name"
                  type="text"
                  name="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Наставник
              </label>
              <Multiselect
                  v-model="team.mentor"
                  placeholder="Выберите наставника ..."
                  :filter-results="false"
                  :min-chars="1"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Наставники не найдены'"
                  ref="mentorSelect"
                  :options="async function(query) {
                    return await fetchMentors(query)
                  }"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Участники
              </label>
              <Multiselect
                  v-model="team.members"
                  mode="tags"
                  placeholder="Выберите участников ..."
                  :filter-results="false"
                  :min-chars="1"
                  :resolve-on-load="false"
                  :delay="0"
                  :canClear="false"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Участники не найдены'"
                  ref="memberSelect"
                  :options="async function(query) {
                            return await fetchMembers(query)
                          }"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Регион
              </label>
              <Multiselect
                  v-model="team.region"
                  placeholder="Выберите регион ..."
                  :filter-results="false"
                  :min-chars="2"
                  :resolve-on-load="false"
                  :delay="0"
                  :canClear="false"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Регионы не найдены'"
                  ref="regionSelect"
                  :options="async function(query) {
                    return await fetchRegions(query)
                  }"/>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Населенный пункт
              </label>
              <Multiselect
                  v-model="team.city"
                  placeholder="Выберите населенный пункт ..."
                  :filter-results="false"
                  :min-chars="2"
                  :resolve-on-load="false"
                  :delay="0"
                  :canClear="false"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Населенные пункты не найдены'"
                  ref="citySelect"
                  :options="async function(query) {
                    return await fetchCities(query)
                  }"/>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Школа
              </label>
              <Multiselect
                  v-model="team.school"
                  placeholder="Выберите школу ..."
                  :filter-results="false"
                  :min-chars="2"
                  :resolve-on-load="false"
                  :delay="0"
                  :canClear="false"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Школы не найдены'"
                  ref="schoolsSelect"
                  :options="async function(query) {
                    return await fetchSchools(query)
                  }"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex mx-auto mt-6 mb-20">
          <button type="submit"
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Сохранить
          </button>
          <button @click.prevent="cancel()"
                  class="bg-blueGray-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Отмена
          </button>
        </div>
      </form>

    </div>
  </div>
</template>
<script>

import accountService from '@/services/account.service'
import schoolService from '@/services/api/school.service'
import cityService from '@/services/city.service'
import regionService from '@/services/region.service'
import teamService from '@/services/team.service'
import {humanizeName, isNullOrEmpty} from '@/utils'
import Multiselect from '@vueform/multiselect'
import {ref} from 'vue'
import {useRouter} from 'vue-router'

export default {
  name: 'TeamEdit',
  components: {
    Multiselect
  },
  setup() {
    const router = useRouter()
    const errorMessage = ref(null)
    const team = ref({
      name: '',
      region: null,
      city: null,
      school: null,
      mentor: null,
      members: []
    })
    const regions = ref([])

    const regionSelect = ref(null)
    const citySelect = ref(null)
    const mentorSelect = ref(null)
    const schoolSelect = ref(null)
    const memberSelect = ref(null)

    const fetchMentors = async (query) => {
      const response = await accountService.search({
        filter: query.toLowerCase(),
        role: 'MENTOR',
        size: 20
      })

      return response.data.content.map((mentor) => {
        return {
          value: mentor.id,
          label: humanizeName(mentor)
        }
      })
    }

    const fetchMembers = async (query) => {
      const response = await accountService.search({
        filter: query.toLowerCase(),
        role: 'MEMBER',
        size: 20
      })

      return response.data.content.map((member) => {
        return {
          value: member.id,
          label: humanizeName(member)
        }
      })
    }

    const fetchRegions = async (query) => {
      const response = await regionService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((region) => {
        return {
          value: region.id,
          label: region.name
        }
      })
    }

    const fetchCities = async (query) => {
      const params = {
        filter: query.toLowerCase(),
        size: 20
      }
      if (team.value.region) {
        params.region = team.value.region
      }
      const response = await cityService.search(params)

      return response.data.content.map((city) => {
        return {
          value: city.id,
          label: city.name
        }
      })
    }

    const fetchSchools = async (query) => {
      const params = {
        filter: query.toLowerCase(),
        size: 20
      }

      if (team.value.city) {
        params.city = team.value.city
      }
      if (team.value.region) {
        params.region = team.value.region
      }

      const response = await schoolService.search(params)

      return response.data.content.map((school) => {
        return {
          value: school.id,
          label: school.name
        }
      })
    }

    const cancel = async () => {
      await router.push(`/my/teams`)
    }

    const update = async () => {
      if (isNullOrEmpty(team.value.name)) {
        errorMessage.value = 'Задайте название команды'
        return
      }
      if (!(team.value.mentor && team.value.mentor > 0)) {
        errorMessage.value = 'Задайте наставника'
        return
      }
      if (team.value.members.length === 0) {
        errorMessage.value = 'Добавьте хотя бы одного участника'
        return
      }

      errorMessage.value = null
      teamService.create({
        name: team.value.name,
        mentor: {
          id: team.value.mentor
        },
        region: {
          id: team.value.region
        },
        city: {
          id: team.value.city
        },
        school: {
          id: team.value.school
        },
        members: team.value.members.map(member => {
          return {
            id: member
          }
        })
      })
          .then((response) => {
            router.push(`/my/teams?after_edit=${response.data.id}`)
          })
          .catch((error) => {
            console.error(error)
            errorMessage.value = error.toString()
          })
    }

    return {
      team,
      regions,
      errorMessage,
      regionSelect,
      citySelect,
      schoolSelect,
      mentorSelect,
      memberSelect,
      update,
      cancel,
      fetchRegions,
      fetchCities,
      fetchMentors,
      fetchSchools,
      fetchMembers
    }
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>