<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">
            Шахматная олимпиада <span>{{ contest.name }}</span>
          </h1>
        </div>
      </div>
    </div>
    <hr class="mb-5">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white min-h-screen-75">
      <div v-if="waiting">
        <p class="text-blueGray-400 p-4">Ожидайте подведения итогов ...</p>
      </div>
      <div v-else>
        <ContestNavigation :contest="contest"/>
      </div>
    </div>
  </div>
</template>

<script>

import contestService from '@/services/contest.service'
import ContestNavigation from '@/views/my/contests/ContestNavigation'
import {ref} from 'vue'
import {useRoute} from 'vue-router'

export default {
  name: 'ContestResult',
  components: {ContestNavigation},
  setup() {
    const route = useRoute()
    const contest = ref({})
    const result = ref({})
    const solutions = ref([])
    const personalResults = ref([])
    const teamResults = ref([])
    const waiting = ref(true)

    contestService.getTeamResults(route.params.id)
        .then(response => {
          contest.value = response.data.contest || {}
          result.value = response.data.result || {}
          solutions.value = response.data.solutions || []
          personalResults.value = response.data.personalResults || []
          teamResults.value = response.data.teamResults || []
          waiting.value = response.data.waiting
        })

    return {
      contest,
      result,
      solutions,
      personalResults,
      teamResults,
      waiting
    }
  }
}
</script>
