<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Новая олимпиада</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="save">
        <div v-if="errorMessage" class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                    <span class="inline-block align-middle mr-8">
                      <strong>Ошибка при сохранении олимпиады:</strong> {{ errorMessage }}
                    </span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Статус:
              </label>
              <Multiselect
                  v-model="contest.status"
                  placeholder="Выберите статус"
                  :options="contestStatuses"/>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Название:
              </label>
              <input
                  v-model="contest.name"
                  type="text"
                  name="name"
                  placeholder="Задайте название олимпиады ..."
                  required="true"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Описание олимпиады:
              </label>
              <textarea
                  v-model="contest.description"
                  placeholder="Задайте описание олимпиады ..."
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  rows="6"/>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Дата и время начала:
              </label>
              <VueDatePicker
                  v-model="contest.startedAt"
                  locale="ru"
                  cancelText="Отмена"
                  selectText="Выбрать"
                  timezone="Europe/Moscow"
                  format="dd.MM.yyyy HH:mm"/>
              <span class="text-gray-500 text-sm">Указавайте время в часовом поясе МСК</span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Выберите задачи:
              </label>
              <Multiselect
                  v-model="contest.issues"
                  mode="tags"
                  placeholder="Выберите задачи ..."
                  noResultsText="Задачи не найдены"
                  noOptionsText="Нет доступных задач"
                  :close-on-select="false"
                  :searchable="true"
                  :options="issues"
                  @change="updateIssues()"
                  ref="issuesSelect"/>
              <a v-if="isAllIssuesSelected" href="#" @click.prevent="addAllIssues()"
                 class="text-gray-500 text-xs mr-3 mt-2 dashed-underline">Добавить все задачи</a>
              <a v-if="isAllIssuesUnselected" href="#" @click.prevent="removeAllIssues()"
                 class="text-red-500 text-xs mt-2 dashed-underline">Удалить все задачи</a>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <fieldset>
                <legend
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Определите формат заданий:
                </legend>

                <div class="container px-4 mx-auto">
                  <div class="flex flex-wrap">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Тип задания</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Количество задач</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Баллы за правильное решение</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block p-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center ">Баллы за неправильное решение</span>
                    </div>
                  </div>
                  <div class="flex flex-wrap mt-2">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block py-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Мат в 1 ход</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.checkmateIn1MoveIssuesCount" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.checkmateIn1MovePoints" type="number" min="-100" max="0" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.checkmateIn1MoveFoulPoints" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                  </div>

                  <div class="flex flex-wrap mt-2">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block py-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Обреченный король</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.doomedKingIssuesCount" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.doomedKingPoints" type="number" min="-100" max="0" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.doomedKingFoulPoints" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                  </div>

                  <div class="flex flex-wrap mt-2">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block py-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Лабиринт</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.labyrinthIssuesCount" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.labyrinthPoints" type="number" min="-100" max="0" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.labyrinthFoulPoints" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                  </div>

                  <div class="flex flex-wrap mt-2">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block py-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Поддавки</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.giveawayIssuesCount" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.giveawayPoints" type="number" min="-100" max="0" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.giveawayFoulPoints" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                  </div>

                  <div class="flex flex-wrap mt-2">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block py-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Матовая сеть</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.checkmateNetworkIssuesCount" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.checkmateNetworkPoints" type="number" min="-100" max="0" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.checkmateNetworkFoulPoints" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                  </div>

                  <div class="flex flex-wrap mt-2">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block py-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Тест</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.testIssuesCount" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.testPoints" type="number" min="-100" max="0" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.testFoulPoints" type="number" min="0" max="100" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                  </div>

                  <div class="flex flex-wrap mt-5">
                    <div class="w-full px-4 flex-1">
                    <span
                        class="text-sm block py-3 text-blueGray-700 rounded border border-solid border-blueGray-100 text-center">Количество минут на решение задач:</span>
                    </div>
                    <div class="w-full px-4 flex-1">
                      <input v-model="contest.duration" type="number" min="1" max="120" step="1"
                             class="text-center border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>
                    </div>
                    <div class="w-full px-4 flex-1"></div>
                    <div class="w-full px-4 flex-1"></div>
                  </div>
                </div>
              </fieldset>

              <fieldset class="mt-5" style="padding: 20px !important;">
                <legend
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Определите вид первенства:
                </legend>
                <div class="flex flex-wrap mt-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Тип первенства:
                      </label>
                      <Multiselect
                          v-model="contest.type"
                          placeholder="Выберите вид первенства ..."
                          :options="contestTypes"/>
                    </div>
                  </div>
                </div>

                <div v-if="isTeamContest" class="flex flex-wrap mt-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Минимальное количество членов команды:
                      </label>
                      <input v-model="contest.minTeamMembers" type="number" min="1" max="100" step="1"
                             class="border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>

                    </div>
                  </div>
                </div>

                <div v-if="isTeamContest" class="flex flex-wrap mt-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Максимальное количество членов команды:
                      </label>
                      <input v-model="contest.maxTeamMembers" type="number" min="1" max="100" step="1"
                             class="border-0  py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"/>

                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset class="mt-5" style="padding: 0 20px 0px !important;">
                <legend
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  География:
                </legend>
                <div class="flex flex-wrap mt-6 mb-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Регионы
                      </label>
                      <Multiselect
                          v-model="contest.regions"
                          mode="tags"
                          placeholder="Выберите регионы ..."
                          :filter-results="false"
                          :min-chars="2"
                          :resolve-on-load="false"
                          :delay="0"
                          :canClear="false"
                          :searchable="true"
                          :noOptionsText="'Введите несколько символов'"
                          :noResultsText="'Регионы не найдены'"
                          ref="regionSelect"
                          :options="async function(query) {
                            return await fetchRegions(query)
                          }"/>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap mt-6 mb-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Населенные пункты
                      </label>
                      <Multiselect
                          v-model="contest.cities"
                          mode="tags"
                          placeholder="Выберите наставников ..."
                          :filter-results="false"
                          :min-chars="2"
                          :resolve-on-load="false"
                          :delay="0"
                          :canClear="false"
                          :searchable="true"
                          :noOptionsText="'Введите несколько символов'"
                          :noResultsText="'Населенные пункты не найдены'"
                          ref="citySelect"
                          :options="async function(query) {
                            return await fetchCities(query)
                          }"/>
                    </div>
                  </div>
                </div>

                <div class="flex flex-wrap mt-6 mb-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Школы
                      </label>
                      <Multiselect
                          v-model="contest.schools"
                          mode="tags"
                          placeholder="Выберите школы ..."
                          :filter-results="false"
                          :min-chars="2"
                          :resolve-on-load="false"
                          :delay="0"
                          :canClear="false"
                          :searchable="true"
                          :noOptionsText="'Введите несколько символов'"
                          :noResultsText="'Школы не найдены'"
                          ref="schoolSelect"
                          :options="async function(query) {
                            return await fetchSchools(query)
                          }"/>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset class="mt-5" style="padding: 0 20px 20px !important;">
                <legend
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Наставники:
                </legend>
                <div class="flex flex-wrap mt-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Выберите наставников:
                      </label>
                      <Multiselect
                          v-model="contest.mentors"
                          mode="tags"
                          placeholder="Выберите наставников ..."
                          :filter-results="false"
                          :min-chars="2"
                          :resolve-on-load="false"
                          :delay="0"
                          :canClear="false"
                          :searchable="true"
                          :noOptionsText="'Введите несколько символов'"
                          :noResultsText="'Наставники не найдены'"
                          ref="mentorSelect"
                          :options="async function(query) {
                            return await fetchMentors(query)
                          }"/>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset v-if="isTeamContest" class="mt-5" style="padding: 0 20px 20px !important;">
                <legend
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Команды:
                </legend>
                <div class="flex flex-wrap mt-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Выберите команды:
                      </label>
                      <Multiselect
                          v-model="contest.teams"
                          mode="tags"
                          placeholder="Выберите команды ..."
                          :filter-results="false"
                          :min-chars="2"
                          :resolve-on-load="false"
                          :delay="0"
                          :canClear="false"
                          :searchable="true"
                          :noOptionsText="'Введите несколько символов'"
                          :noResultsText="'Команды не найдены'"
                          ref="teamSelect"
                          :options="async function(query) {
                            return await fetchTeams(query)
                          }"/>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset class="mt-5" style="padding: 0 20px 20px !important;">
                <legend
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Участники:
                </legend>
                <div class="flex flex-wrap mt-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Выберите участников:
                      </label>
                      <Multiselect
                          v-model="contest.members"
                          mode="tags"
                          placeholder="Выберите участников ..."
                          :filter-results="false"
                          :min-chars="2"
                          :resolve-on-load="false"
                          :delay="0"
                          :canClear="false"
                          :searchable="true"
                          :noOptionsText="'Введите несколько символов'"
                          :noResultsText="'Участники не найдены'"
                          ref="memberSelect"
                          :options="async function(query) {
                            return await fetchMembers(query)
                          }"/>
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset class="mt-5" style="padding: 0 20px 20px !important;">
                <legend
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                  Контроллеры:
                </legend>
                <div class="flex flex-wrap mt-6">
                  <div class="w-full lg:w-12/12">
                    <div class="relative w-full mb-3">
                      <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                        Выберите контроллеров:
                      </label>
                      <Multiselect
                          v-model="contest.controllers"
                          mode="tags"
                          placeholder="Выберите контроллеров ..."
                          :filter-results="false"
                          :min-chars="2"
                          :resolve-on-load="false"
                          :delay="0"
                          :canClear="false"
                          :searchable="true"
                          :noOptionsText="'Введите несколько символов'"
                          :noResultsText="'Контроллеры не найдены'"
                          ref="controllerSelect"
                          :options="async function(query) {
                            return await fetchControllers(query)
                          }"/>
                    </div>
                  </div>
                </div>

              </fieldset>

            </div>
          </div>
        </div>

        <div class="flex mx-auto mt-6 mb-20">
          <button @click.prevent="save()"
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Сохранить
          </button>
          <button @click.prevent="cancel()"
                  class="bg-blueGray-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Отмена
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>

import {valueOf} from '@/chess/issueType'
import accountService from '@/services/account.service'
import schoolService from '@/services/api/school.service'
import cityService from '@/services/city.service'
import contestService from '@/services/contest.service'
import issueService from '@/services/issue.service'
import regionService from '@/services/region.service'
import teamService from '@/services/team.service'
import {humanizeName, isNullOrEmpty} from '@/utils'
import Multiselect from '@vueform/multiselect'
import moment from 'moment'
import {computed, ref} from 'vue'
import {useRouter} from 'vue-router'

export default {
  name: 'ContestNew',
  components: {
    Multiselect
  },
  setup() {
    const router = useRouter()
    const errorMessage = ref('')

    const contestStatuses = ref([
      {
        value: 'DRAFT',
        label: 'Черновик'
      },
      {
        value: 'ACTIVE',
        label: 'Опубликован'
      },
      {
        value: 'ARCHIVE',
        label: 'Архив'
      }
    ])

    const contestTypes = ref([
      {
        value: 'MIXED',
        label: 'Лично-командное'
      },
      {
        value: 'PERSONAL',
        label: 'Личное'
      },
      {
        value: 'TEAM',
        label: 'Командное'
      }
    ])

    const contest = ref({

      status: 'DRAFT',
      name: null,
      description: null,
      startedAt: new Date(),
      issues: [],
      controllers: [],
      members: [],
      mentors: [],
      teams: [],
      regions: [],
      cities: [],
      schools: [],

      checkmateIn1MovePoints: 100,
      checkmateIn1MoveFoulPoints: -10,
      checkmateIn1MoveIssuesCount: 1,

      doomedKingPoints: 100,
      doomedKingFoulPoints: -10,
      doomedKingIssuesCount: 1,

      labyrinthPoints: 100,
      labyrinthFoulPoints: -10,
      labyrinthIssuesCount: 1,

      giveawayPoints: 100,
      giveawayFoulPoints: -10,
      giveawayIssuesCount: 1,

      checkmateNetworkPoints: 100,
      checkmateNetworkFoulPoints: -10,
      checkmateNetworkIssuesCount: 1,

      testPoints: 10,
      testFoulPoints: 0,
      testIssuesCount: 5,

      duration: 40,

      type: 'PERSONAL',
      minTeamMembers: 3,
      maxTeamMembers: 5
    })


    const issues = ref([])

    const updateIssues = (e) => {
      console.log('updateIssues', e)
    }

    const issuesSelect = ref(null)

    const addAllIssues = () => {
      issuesSelect.value.selectAll()
    }

    const removeAllIssues = () => {
      issuesSelect.value.clear()
    }

    const isAllIssuesSelected = computed(() => {
      return contest.value.issues.length !== issues.value.length
    })

    const isAllIssuesUnselected = computed(() => {
      return contest.value.issues.length > 0
    })

    // TODO гипотетически тут надо выгружать через пагинацию все
    issueService.search({size: 10_000})
        .then(response => {
          for (const issue of response.data.content) {
            const type = valueOf(issue.type)
            issues.value.push({
              value: issue.id,
              label: `#${issue.id} ${type ? type.name : ''}`
            })
          }
        })

    const save = () => {
      if (confirm('Сохранить и закрыть олимпиаду?')) {

        if (isNullOrEmpty(contest.value.name)) {
          errorMessage.value = 'Задайте название олимпиады'
          return
        }

        const idToEntity = (id) => {
          return {id}
        }

        errorMessage.value = ''
        contestService.create({
          name: contest.value.name,
          description: contest.value.description,
          status: contest.value.status,
          type: contest.value.type,
          startedAt: moment(contest.value.startedAt).format('DD/MM/YYYY HH:mm'),

          duration: contest.value.duration || 40,
          minTeamMembers: contest.value.minTeamMembers || 3,
          maxTeamMembers: contest.value.maxTeamMembers || 5,

          checkmateIn1MovePoints: contest.value.checkmateIn1MovePoints || 100,
          doomedKingPoints: contest.value.doomedKingPoints || 100,
          labyrinthPoints: contest.value.labyrinthPoints || 100,
          giveawayPoints: contest.value.giveawayPoints || 100,
          checkmateNetworkPoints: contest.value.checkmateNetworkPoints || 100,
          testPoints: contest.value.testPoints || 10,

          checkmateIn1MoveFoulPoints: contest.value.checkmateIn1MoveFoulPoints || -10,
          doomedKingFoulPoints: contest.value.doomedKingFoulPoints || -10,
          labyrinthFoulPoints: contest.value.labyrinthFoulPoints || -10,
          giveawayFoulPoints: contest.value.giveawayFoulPoints || -10,
          checkmateNetworkFoulPoints: contest.value.checkmateNetworkFoulPoints || -10,
          testFoulPoints: contest.value.testFoulPoints || 0,

          checkmateIn1MoveIssuesCount: contest.value.checkmateIn1MoveIssuesCount || 1,
          doomedKingIssuesCount: contest.value.doomedKingIssuesCount || 1,
          labyrinthIssuesCount: contest.value.labyrinthIssuesCount || 1,
          giveawayIssuesCount: contest.value.labyrinthIssuesCount || 1,
          checkmateNetworkIssuesCount: contest.value.checkmateNetworkIssuesCount || 1,
          testIssuesCount: contest.value.testIssuesCount || 1,

          issues: contest.value.issues.map(idToEntity),

          regions: contest.value.regions.map(idToEntity),
          cities: contest.value.cities.map(idToEntity),
          schools: contest.value.schools.map(idToEntity),

          mentors: contest.value.mentors.map(idToEntity),
          controllers: contest.value.controllers.map(idToEntity),
          members: contest.value.members.map(idToEntity),
          teams: contest.value.teams.map(idToEntity)
        })
            .then((response) => {
              console.log(response)
              router.push(`/my/contests`)
            })
            .catch((error) => {
              console.error(error)
              errorMessage.value = error.data
            })
      }
    }

    const fetchRegions = async (query) => {
      const response = await regionService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((region) => {
        return {
          value: region.id,
          label: region.name
        }
      })
    }

    const fetchCities = async (query) => {
      const response = await cityService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((city) => {
        return {
          value: city.id,
          label: city.name
        }
      })
    }

    const fetchSchools = async (query) => {
      const response = await schoolService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((city) => {
        return {
          value: city.id,
          label: city.name
        }
      })
    }

    const fetchMentors = async (query) => {
      const response = await accountService.search({
        filter: query.toLowerCase(),
        role: 'MENTOR',
        size: 20
      })

      return response.data.content.map((mentor) => {
        return {
          value: mentor.id,
          label: humanizeName(mentor)
        }
      })
    }

    const fetchControllers = async (query) => {
      const response = await accountService.search({
        filter: query.toLowerCase(),
        role: 'CONTROLLER',
        size: 20
      })

      return response.data.content.map((controller) => {
        return {
          value: controller.id,
          label: humanizeName(controller)
        }
      })
    }

    const fetchMembers = async (query) => {
      const response = await accountService.search({
        filter: query.toLowerCase(),
        role: 'MEMBER',
        size: 20
      })

      return response.data.content.map((member) => {
        return {
          value: member.id,
          label: humanizeName(member)
        }
      })
    }

    const fetchTeams = async (query) => {
      const response = await teamService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((team) => {
        return {
          value: team.id,
          label: team.name
        }
      })
    }

    const isTeamContest = computed(() => {
      return ['TEAM', 'MIXED'].includes(contest.value.type)
    })

    const cancel = () => {
      router.push('/my/contests')
    }

    return {
      // vars
      contest,
      errorMessage,
      issues,
      issuesSelect,
      contestTypes,
      contestStatuses,
      // computed
      isTeamContest,
      isAllIssuesSelected,
      isAllIssuesUnselected,
      // fns
      save,
      cancel,
      updateIssues,
      addAllIssues,
      removeAllIssues,
      fetchRegions,
      fetchCities,
      fetchSchools,
      fetchMentors,
      fetchControllers,
      fetchMembers,
      fetchTeams
    }
  }
}
</script>

<style>
.dashed-underline {
  text-decoration: underline;
  text-decoration-style: dashed;
}

fieldset {
  border-width: 1px !important;
  border-style: dotted !important;
  border-color: rgb(200, 200, 200) !important;
  border-radius: 5px !important;
  padding: 5px 5px 25px 5px !important;
}
</style>
