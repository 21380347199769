<template>
  <div>
    <navbar/>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
            class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            :style="`background-image: url('${registerBg2}');`"
        ></div>
        <TrainerComponent :show-video="true"/>
        <footer-small absolute/>
      </section>
    </main>
  </div>
</template>
<script>
import registerBg2 from '@/assets/img/register_bg_2.png'
import FooterSmall from '@/components/Footers/FooterSmall.vue'
import Navbar from '@/components/Navbars/AuthNavbar.vue'
import TrainerComponent from '@/views/my/trainer/Index.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Trainer',
  data() {
    return {
      registerBg2
    }
  },
  components: {
    Navbar,
    FooterSmall,
    TrainerComponent
  }
}
</script>
