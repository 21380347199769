import App from '@/App.vue'

import '@/assets/styles/tailwind.css'

// layouts
import Admin from '@/layouts/Admin.vue'
import Auth from '@/layouts/Auth.vue'
import ErrorPage from '@/layouts/ErrorPage.vue'
import JoinPage from '@/layouts/JoinPage'
import Trainer from '@/layouts/Trainer.vue'

// views
import Login from '@/views/auth/LoginPage.vue'
import Logout from '@/views/auth/LogoutPage.vue'
import AccountEdit from '@/views/my/accounts/AccountEdit.vue'
import AccountList from '@/views/my/accounts/AccountList.vue'
import AccountNew from '@/views/my/accounts/AccountNew.vue'
import AccountView from '@/views/my/accounts/AccountView.vue'
import ContestEdit from '@/views/my/contests/ContestEdit.vue'
import ContestList from '@/views/my/contests/ContestList.vue'
import ContestMembersResult from '@/views/my/contests/ContestMembersResult'
import ContestNew from '@/views/my/contests/ContestNew.vue'
import ContestPage from '@/views/my/contests/ContestPage'
import ContestReports from '@/views/my/contests/ContestReports'
import ContestResult from '@/views/my/contests/ContestResult'
import ContestTeamsResult from '@/views/my/contests/ContestTeamsResult'
import ContestView from '@/views/my/contests/ContestView.vue'
import Dashboard from '@/views/my/dashboard/DashboardPage.vue'
import CityEdit from '@/views/my/geo/CityEdit'
import CityList from '@/views/my/geo/CityList'
import CityNew from '@/views/my/geo/CityNew'
import RegionEdit from '@/views/my/geo/RegionEdit'
import RegionList from '@/views/my/geo/RegionList'
import RegionNew from '@/views/my/geo/RegionNew'
import SchoolEdit from '@/views/my/geo/SchoolEdit'
import SchoolList from '@/views/my/geo/SchoolList'
import SchoolNew from '@/views/my/geo/SchoolNew'
import IssueEdit from '@/views/my/issues/IssueEdit.vue'
import IssueList from '@/views/my/issues/IssueList.vue'
import IssueNew from '@/views/my/issues/IssueNew.vue'
import IssueView from '@/views/my/issues/IssueView.vue'
import Profile from '@/views/my/profile/Index.vue'
import TeamEdit from '@/views/my/teams/TeamEdit'
import TeamList from '@/views/my/teams/TeamList'
import TeamNew from '@/views/my/teams/TeamNew'
import TrainerComponent from '@/views/my/trainer/Index.vue'

import VueCountdown from '@chenfengyuan/vue-countdown'

// styles
import '@fortawesome/fontawesome-free/css/all.min.css'

import VueVideoPlayer from '@videojs-player/vue'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import 'video.js/dist/video-js.css'

import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'


// routes
const routes = [
    {
        path: '/my',
        redirect: '/my/dashboard',
        component: Admin,
        children: [
            {
                path: '/my/dashboard',
                component: Dashboard
            },
            {
                path: '/my/issues',
                component: IssueList
            },
            {
                path: '/my/issues/:id',
                component: IssueView,
                props: true

            },
            {
                path: '/my/issues/:id/edit',
                component: IssueEdit,
                props: true
            },
            {
                path: '/my/issues/new',
                component: IssueNew
            },
            {
                path: '/my/contests',
                component: ContestList
            },
            {
                path: '/my/contests/:id',
                component: ContestView,
                props: true
            },
            {
                path: '/my/:id/start',
                component: ContestPage,
                props: true
            },
            {
                path: '/my/:id/result',
                component: ContestResult,
                props: true
            },
            {
                path: '/my/:id/result/members',
                component: ContestMembersResult,
                props: true
            },
            {
                path: '/my/:id/result/teams',
                component: ContestTeamsResult,
                props: true
            },
            {
                path: '/my/:id/result/reports',
                component: ContestReports,
                props: true
            },
            {
                path: '/my/contests/:id/edit',
                component: ContestEdit,
                props: true
            },
            {
                path: '/my/contests/new',
                component: ContestNew
            },
            {
                path: '/my/accounts',
                component: AccountList
            },
            {
                path: '/my/accounts/:id',
                component: AccountView,
                props: true
            },
            {
                path: '/my/accounts/:id/edit',
                component: AccountEdit,
                props: true
            },
            {
                path: '/my/accounts/new',
                component: AccountNew
            },

            {
                path: '/my/regions',
                component: RegionList
            },
            {
                path: '/my/regions/:id/edit',
                component: RegionEdit,
                props: true
            },
            {
                path: '/my/regions/new',
                component: RegionNew
            },

            {
                path: '/my/cities',
                component: CityList
            },
            {
                path: '/my/cities/:id/edit',
                component: CityEdit,
                props: true
            },
            {
                path: '/my/cities/new',
                component: CityNew
            },

            {
                path: '/my/schools',
                component: SchoolList
            },
            {
                path: '/my/schools/:id/edit',
                component: SchoolEdit,
                props: true
            },
            {
                path: '/my/schools/new',
                component: SchoolNew
            },

            {
                path: '/my/teams',
                component: TeamList
            },
            {
                path: '/my/teams/:id/edit',
                component: TeamEdit,
                props: true
            },
            {
                path: '/my/teams/new',
                component: TeamNew
            },

            {
                path: '/my/trainer',
                component: TrainerComponent
            },
            {
                path: '/my/profile',
                component: Profile
            }
        ]
    },
    {
        path: '/login',
        component: Auth,
        children: [
            {
                path: '/login/',
                component: Login,
                props: true
            },
            {
                path: '/login/:error_msg',
                component: Login,
                props: true
            }
        ]
    },
    {
        path: '/join/:id',
        component: JoinPage
    },
    {
        path: '/logout',
        component: Logout
    },
    {
        path: '/error',
        component: ErrorPage
    },
    {
        path: '/contest',
        component: Trainer
    },
    {path: '/:pathMatch(.*)*', redirect: '/my/dashboard'}
]

export const router = createRouter({
    history: createWebHistory(),
    routes
})

createApp(App)
    .use(router)
    .use(VueVideoPlayer)
    .component('VueDatePicker', VueDatePicker)
    .component(VueCountdown.name, VueCountdown)
    .mount('#app')
