import CrudService from '@/services/crud.service.js'

class RegionService extends CrudService {

    constructor() {
        super('regions')
    }

}

export default new RegionService()