<template>
  <div></div>
</template>
<script>

import {onMounted} from 'vue'
import {useRouter} from 'vue-router'

export default {
  name: 'LogoutPage',
  setup() {
    const router = useRouter()
    onMounted(() => {
      localStorage.removeItem('auth')
      localStorage.removeItem('account')
      router.push({path: '/login'})
    })
  },
}
</script>
