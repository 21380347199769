<template>
  <div
      class="
        min-h-screen
        bg-emerald-300
    flex
    items-center
    justify-center
  "
  >
    <div style="padding-left: 80px; padding-right: 80px"
         class="py-10 bg-white rounded-md shadow-xl">
      <div class="flex flex-col items-center">
        <h1 class="font-bold text-blue-600 text-9xl">404</h1>

        <h6 class="mb-2 text-2xl font-bold text-center text-gray-800">
          Страница не найдена
        </h6>
        <button
            class="bg-emerald-500 text-white active:bg-emerald-200 font-bold uppercase text-xs my-6 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
            type="button"
        >
          <router-link
              to="/my/dashboard"
              class="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100">
            На главную
          </router-link>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({})
</script>

<style scoped>

</style>
