import CrudService from '@/services/crud.service.js'

class TeamService extends CrudService {

    constructor() {
        super('teams')
    }

}

export default new TeamService()