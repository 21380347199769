import squares from "@/chess/squares.js";

const checkIfWhiteEmpty = (cg) => {

    for (let i = 0; i < squares.length; i ++) {
        const piece = cg.state.pieces.get(squares[i])
        if (piece && piece.color === 'white') {
            return false
        }
    }
    return true
}

const getLastMoveFromPair = (solution, move) => {
    if (move.color === 'w' && solution.moves[move.index - 1].length > 1) {
        return solution.moves[move.index - 1][1]
    } else {
        return move
    }
}

const formatMove = (move, fen, index) => {
    move.promotion = move.promotion ? move.promotion.toUpperCase() : move.promotion
    const javaSolution = [move.from, move.to, move.promotion].join('')
    return {
        note: move.san,
        color: move.color,
        from: move.from,
        to: move.to,
        java: javaSolution,
        fen,
        index
    }
}

export {
    checkIfWhiteEmpty,
    getLastMoveFromPair,
    formatMove
}

