<template>
  <div class="flex flex-wrap">
    PROFILE
  </div>
</template>
<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Profile',
  components: {},
}
</script>
