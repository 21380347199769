import issueType from '@/chess/issueType'

export default (issues) => {

    console.log('before', issues)
    const index = {i: 0}
    issues
        .filter((issue) => {
            return issue.type === issueType.CHECKMATE_IN_1_MOVE.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index.i++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.DOOMED_KING.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index.i++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.LABYRINTH.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index.i++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.GIVEAWAY.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index.i++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.CHECKMATE_NETWORK.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index.i++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.TEST.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index.i++
        })
    console.log('after', issues)
}