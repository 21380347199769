<template>
  <div v-if="visible" id="promotion-choice" class="top">
    <square v-for="p in pieces"
            :key="p"
            :style="{ top: getTop(p), left: getLeft() }"
            @click="selectPiece(p)">
      <piece class="white" :class="p"></piece>
    </square>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Promotion',
  props: {},
  data() {
    return {
      orig: null,
      dest: null,
      visible: false,
      pieces: ['queen', 'rook', 'bishop', 'knight']
    }
  },
  methods: {
    show(orig, dest) {
      this.orig = orig
      this.dest = dest
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    getTop(piece) {
      switch (piece) {
        case 'queen':
          return '0%'
        case 'rook':
          return '12.5%'
        case 'bishop':
          return '25%'
        case 'knight':
          return '37.5%'
      }
      return '0%'
    },
    getLeft() {
      if (this.dest) {
        switch (this.dest[0]) {
          case 'a':
            return '0%'
          case 'b':
            return '12.5%'
          case 'c':
            return '25%'
          case 'd':
            return '37.5%'
          case 'e':
            return '50%'
          case 'f':
            return '62.5%'
          case 'g':
            return '75%'
          case 'h':
            return '87.5%'
        }
      }
      return '0%'
    },
    selectPiece(piece) {
      this.hide()
      this.$emit('piece-selected', {
        piece,
        orig: this.orig,
        dest: this.dest
      })
    },
  }
}
</script>

<style>
@import '../../../assets/styles/promotion.css';
</style>
