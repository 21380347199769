<template>
  <div class="flex flex-col w-full mb-20 border-2 border-indigo-500 m-6 p-6 rounded">
    <div class="flex flex-wrap mt-6 px-6">
      <div class="w-full lg:w-12/12">
        <div class="relative w-full mb-3">
          <label class="block text-blueGray-600 font-bold mb-2">
            Текст задания:
          </label>
          <textarea
              v-model="currentTask.question"
              placeholder="Вопрос ..."
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              rows="6"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-wrap mt-6 px-6">
      <div class="w-full lg:w-12/12">
        <div class="relative w-full mb-3">
          <label class="block text-blueGray-600 font-bold mb-2">
            Ответы:
          </label>
        </div>
      </div>
    </div>

    <div class="flex flex-wrap mt-6 px-6 w-full">
      <div v-for="(answer, index) in currentTask.answers" :key="index"
           class="w-full border-2 border-indigo-500 m-6 p-6 rounded mb-6 px-6 py-6">
        <label class="block text-gray-700 text-sm font-bold mb-2">
          Ответ {{ (index + 1) }}:
        </label>
        <textarea
            v-model="answer.text"
            placeholder="Текст ответа ..."
            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            rows="6"
        />
        <div class="mt-2">
          <label class="text-gray-700 text-sm font-bold ml-6">
            <input
                v-model="answer.correct"
                @change="onCorrect(answer)"
                class="px-2 py-1 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline"
                type="checkbox"
                name="correct"/>
            &nbsp;&nbsp;Это правильный ответ
          </label>
        </div>
        <div v-if="currentTask.answers.length > 1" class="mt-6">
          <button @click.prevent="removeAnswer(answer)"
                  class="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Удалить ответ
          </button>
        </div>
      </div>

      <div v-if="currentTask.answers.length < 5" class="flex items-center justify-between px-6 py-6">
        <button @click.prevent="addAnswer()"
                class="bg-lightBlue-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
          Добавить ответ
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import {computed} from 'vue'

export default {
  name: 'IssueEditorTest',
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const currentTask = computed({
      get() {
        return props.task
      },
      set(newTask) {
        this.$emit('update:task', newTask)
      }
    })

    const addAnswer = () => {
      if (currentTask.value.answers.length >= 5) {
        return
      }

      currentTask.value.answers.push({
        id: currentTask.value.answers.length,
        text: '',
        correct: false
      })
    }

    const removeAnswer = (answer) => {
      if (currentTask.value.answers.length === 1) {
        return
      }

      currentTask.value.answers = currentTask.value.answers.filter(a => a.id !== answer.id)
      if (answer.correct) {
        currentTask.value.answers[0].correct = true
      }
    }

    const onCorrect = (answer) => {
      for (const a of currentTask.value.answers) {
        if (answer.id !== a.id) {
          a.correct = false
        }
      }
    }

    return {
      currentTask,
      addAnswer,
      removeAnswer,
      onCorrect
    }
  }
}
</script>

<style>
@import '../../assets/styles/chess.css';
</style>