<template>
  <nav
      class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
        class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
      <button
          class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')">
        <i class="fas fa-bars"></i>
      </button>
      <!-- Brand -->
      <router-link
          class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
          to="/">
        Главная
      </router-link>
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown/>
        </li>
        <li class="inline-block relative">
          <user-dropdown/>
        </li>
      </ul>
      <!-- Collapse -->
      <div
          class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
          v-bind:class="collapseShow">
        <!-- Collapse header -->
        <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <router-link
                  class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                  to="/">
                Главная
              </router-link>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                  type="button"
                  class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  v-on:click="toggleCollapseShow('hidden')">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Heading -->
        <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
          Личный кабинет
        </h6>
        <!-- Navigation -->

        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-center">
            <router-link
                to="/my/dashboard"
                v-slot="{ href, navigate, isActive }">
              <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block"
                  :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                <i
                    class="fas fa-solid fa-gauge mr-2 text-sm"
                    :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                Кабинет
              </a>
            </router-link>
          </li>

          <li v-if="hasAuthority('USERS_EDIT') || hasRole('ADMIN')" class="items-center">
            <router-link
                to="/my/accounts"
                v-slot="{ href, navigate, isActive }">
              <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block"
                  :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                <i
                    class="fas fa-duotone fa-user mr-2 text-sm"
                    :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Пользователи
              </a>
            </router-link>
          </li>

          <li v-if="hasAuthority('TEAMS_EDIT') || hasRole('ADMIN')" class="items-center">
            <router-link to="/my/teams" v-slot="{ href, navigate, isActive }">
              <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block"
                  :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                <i class="fas fa-users mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                Команды
              </a>
            </router-link>
          </li>

          <li v-if="hasAuthority('USERS_EDIT') || hasRole('ADMIN')" class="items-center">
            <router-link to="/my/regions" v-slot="{ href, navigate, isActive }">
              <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block"
                  :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                <i class="fas fa-map mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                География
              </a>
            </router-link>
          </li>

          <li v-if="hasAuthority('CONTESTS_EDIT') || hasRole('ADMIN')" class="items-center">
            <router-link to="/my/contests" v-slot="{ href, navigate, isActive }">
              <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block"
                  :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]">
                <i class="fas fa-table mr-2 text-sm" :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"></i>
                Олимпиады
              </a>
            </router-link>
          </li>

          <li v-if="hasAuthority('ISSUES_EDIT') || hasRole('ADMIN')" class="items-center">
            <router-link to="/my/issues" v-slot="{ href, navigate, isActive }">
              <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block"
                  :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                    class="fas fa-database mr-2 text-sm"
                    :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Задачи
              </a>
            </router-link>
          </li>

          <li class="items-center">
            <router-link to="/my/trainer" v-slot="{ href, navigate, isActive }">
              <a
                  :href="href"
                  @click="navigate"
                  class="text-xs uppercase py-3 font-bold block"
                  :class="[
                  isActive
                    ? 'text-emerald-500 hover:text-emerald-600'
                    : 'text-blueGray-700 hover:text-blueGray-500',
                ]"
              >
                <i
                    class="fas fa-play mr-2 text-sm"
                    :class="[isActive ? 'opacity-75' : 'text-blueGray-300']"
                ></i>
                Тренажер
              </a>
            </router-link>
          </li>
        </ul>

        <!-- Divider -->
        <hr class="my-4 md:min-w-full"/>

        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="items-center">
            <router-link
                class="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
                to="/logout">
              <i class="fas fa-duotone fa-right-from-bracket text-blueGray-300 mr-2 text-sm"></i>
              Выйти
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
); }

<script>
import NotificationDropdown from '@/components/Dropdowns/NotificationDropdown.vue'
import UserDropdown from '@/components/Dropdowns/UserDropdown.vue'
import {ref} from 'vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Sidebar',
  setup() {
    const collapseShow = ref('hidden')
    const toggleCollapseShow = (classes) => {
      collapseShow.value = classes
    }

    const account = JSON.parse(localStorage.getItem('account'))
    console.log('account', account)

    const hasRole = (role) => {
      return account ? account.roles.includes(role) : false
    }

    const hasAuthority = (authority) => {
      return account ? account.authorities.includes(authority) : false
    }

    return {
      collapseShow,
      hasRole,
      hasAuthority,
      toggleCollapseShow
    }
  },
  methods: {},
  components: {
    NotificationDropdown,
    UserDropdown
  }
}
</script>
