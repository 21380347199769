<template>
  <div class="relative bg-emerald-600 md:pt-16 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <div class="flex flex-wrap"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {}
}
</script>
