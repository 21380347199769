<template>
  <nav
      class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg"
  >
    <div
        class="container px-4 mx-auto flex flex-wrap items-center justify-between"
    >
      <div
          class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >

      </div>
      <div
          class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
          :class="[navbarOpen ? 'block rounded shadow-lg' : 'hidden']"
          id="example-navbar-warning"
      >
      </div>
    </div>
  </nav>
</template>
<script>

export default {
  data() {
    return {
      navbarOpen: false,
    }
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen
    },
  },
  components: {},
}
</script>
