import CrudService from '@/services/crud.service.js'

class CityService extends CrudService {

    constructor() {
        super('cities')
    }

}

export default new CityService()