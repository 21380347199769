export default function authHeader() {
    const auth = JSON.parse(localStorage.getItem('auth'))

    if (auth && auth.token) {
        return {
            Authorization: `Bearer ${auth.token.access_token}`,
            'Content-type': 'application/json'
        }
    } else {
        return {
            'Content-type': 'application/json'
        }
    }
}
