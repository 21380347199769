<template>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div
          class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
        <div class="py-2">
          <nav class="block">
            <ul class="flex pl-0 rounded list-none flex-wrap">
              <li v-for="task of counts" :key="task">
                <a @click="$emit('selectTask', task - 1)"
                   :class="{'cursor-pointer border-emerald-200 text-white bg-emerald-500': task !== current,
                   'border-emerald-500 bg-white text-emerald-500': task === current}"
                   class="first:ml-0 text-xs font-semibold flex w-10 h-10 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid">
                  {{ task }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {ref} from 'vue'

export default {
  name: 'TasksSelector',
  props: {
    current: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const counts = ref([])
    for (let i = 1; i <= props.max; i++) {
      counts.value.push(i)
    }

    return {
      counts
    }
  }
}
</script>
