import CrudService from '@/services/crud.service.js'

class IssueService extends CrudService {

    constructor() {
        super('issues')
    }

}

export default new IssueService()