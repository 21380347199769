<template>
  <div class="w-1/2 text-center ml-2 rounded">
    <div class="flex flex-col items-center" style="width: 506px; height: 100%">
      <a style="height: 100%; width: 400px; margin-right: auto"
         class="block border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
        <label class="block uppercase text-blueGray-600 pb-2 text-xs font-bold">
          РЕШЕНИЯ
        </label>
        <div v-if="solution.moves.length === 0 && solutions.length === 0" style="min-height: 27px">
          <span class="text-blueGray-400">Укажите решение на доске ...</span>
        </div>
        <div style="min-height: 27px">
              <span v-for="(solution, id) of solutions" :key="id"
                    :class="{ 'bg-emerald-200 text-emerald-600': solution.successfull,
                            'bg-red-200 text-red-600': solution.failed }"
                    class="mb-3 text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
                  <span v-for="(moves, index) in solution.moves" :key="index">
                    <a @click="$emit('setMove', id, moves[0])">
                      {{ index + 1 }}. {{ formatSAN(moves[0].san) }} {{ ` ` }}
                    </a>
                    <a v-if="moves[1]" @click="$emit('setMove', id, moves[1])">
                      {{ formatSAN(moves[1].san) }} {{ ` ` }}
                    </a>
                  </span>
            </span>
        </div>
        <div class="pt-2">
             <span v-if="solution.moves.length"
                   :class="{
                      'bg-emerald-500 text-white': solution.successfull,
                      'bg-red-600 text-white': solution.failed,
                      'bg-gray-200 text-gray-600': !solution.successfull && !solution.failed
                   }"
                   class="text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1">
               <span v-for="(moves, index) in solution.moves" :key="index"
                     @click="$emit('setMove', false, moves[0])">
                    <a>
                      {{ index + 1 }}. {{ formatSAN(moves[0].san) }} {{ ` ` }}
                    </a>
                    <a v-if="moves.length > 1"
                       @click="$emit('setMove', false, moves[1])">
                      {{ formatSAN(moves[1].san) }} {{ ` ` }}
                    </a>
               </span>
          </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import formatSAN from '@/formatters/san.js'

export default {
  name: 'GiveawaySolutionsCard',
  props: {
    solution: {
      type: Object,
      required: true
    },
    solutions: {
      type: Array,
      required: true
    },
  },
  setup() {

    return {
      formatSAN,
    }
  }
}
</script>

<style>
</style>
