import {router} from '@/main'
import config from '@/services/api/config.js'
import axios from 'axios'
// import * as MockAdapter from "axios-mock-adapter";
// import addMocks from "@/services/api/mock.js";

const http = axios.create({
    baseURL: config.endpoint,
    headers: {
        'Content-type': 'application/json'
    }
})

http.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 403) {
        router.push(`/login/expired`)
    }
    return Promise.reject(error)
})

// const mock = new MockAdapter(http, {delayResponse: 200})
// addMocks(mock)

http.defaults.timeout = 0

export default http
