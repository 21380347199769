class IssueType {

    constructor(type, name, description) {
        this.type = type
        this.name = name
        this.description = description
    }

    getType() {
        return this.type
    }

    getName() {
        return this.name
    }

    getDescription() {
        return this.description
    }
}

const CHECKMATE_IN_1_MOVE = new IssueType(
    'CHECKMATE_IN_1_MOVE',
    'Мат в 1 ход',
    'Найди все маты в один ход черному королю.'
)

const DOOMED_KING = new IssueType(
    'DOOMED_KING',
    'Обреченный король',
    'Добавь чёрного короля, чтобы ему получился мат. Найди как можно больше решений.'
)

const LABYRINTH = new IssueType(
    'LABYRINTH',
    'Лабиринт',
    'Поставь шах чёрному королю. Ходят только белые фигуры, не попадая под удар чёрных фигур при передвижении и взятиях. Запиши маршрут белых фигур.'
)

const GIVEAWAY = new IssueType(
    'GIVEAWAY',
    'Поддавки',
    'Ход белых. Отдай все белые фигуры за 4 хода. Правило игры в «поддавки»: белым и чёрным рубить обязательно.'
)

const CHECKMATE_NETWORK = new IssueType(
    'CHECKMATE_NETWORK',
    'Матовая сеть',
    'Придумай позицию мата чёрному королю, используя заданные чёрные фигуры, а также как можно меньше белых фигур из одного комплекта шахмат (белого короля – обязательно).'
)

const TEST = new IssueType(
    'TEST',
    'Тест',
    'Ответь на вопрос, выбрав из предложенных вариантов.'
)

export const valueOf = (type) => {
    if (CHECKMATE_IN_1_MOVE.getType() === type) {
        return CHECKMATE_IN_1_MOVE
    } else if (DOOMED_KING.getType() === type) {
        return DOOMED_KING
    } else if (LABYRINTH.getType() === type) {
        return LABYRINTH
    } else if (GIVEAWAY.getType() === type) {
        return GIVEAWAY
    } else if (CHECKMATE_NETWORK.getType() === type) {
        return CHECKMATE_NETWORK
    } else if (TEST.getType() === type) {
        return TEST
    } else {
        return null
    }
}

export default {
    CHECKMATE_IN_1_MOVE,
    DOOMED_KING,
    LABYRINTH,
    GIVEAWAY,
    CHECKMATE_NETWORK,
    TEST,
    valueOf,
    types: [
        CHECKMATE_IN_1_MOVE,
        DOOMED_KING,
        LABYRINTH,
        GIVEAWAY,
        CHECKMATE_NETWORK,
        TEST
    ]
}