<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Редактирование пользователя</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form @submit.prevent="update">
        <div v-if="errorMessage" class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                    <span class="inline-block align-middle mr-8">
                      <strong>Ошибка при сохранении пользователя:</strong> {{ errorMessage }}
                    </span>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold">
                Логин
              </label>
              <span class="text-blueGray-400 text-sm">Оставьте пустым, если хотите чтобы логин сгенерировался автоматически</span>
              <input
                  v-model="account.username"
                  type="text"
                  name="username"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Роли пользователя
              </label>
              <vue3-tags-input
                  :tags="account.roles"
                  :select="true"
                  :select-items="roles"
                  :duplicate-select-item="false"
                  :limit="5"
                  @on-select="onAddRole"
                  @on-tags-changed="onRolesChanged">
                <template #item="{ tag }">
                  {{ tag.text }}
                </template>
                <template #no-data>
                  No Data
                </template>
                <template #select-item="tag">
                  {{ tag.text }}
                </template>
              </vue3-tags-input>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Блокировка пользователя
              </label>
              <Toggle v-model="account.disabled" @change="onDisabled()" :disabled="false"/>
            </div>
          </div>
        </div>
        <hr>

        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Email
              </label>
              <input
                  v-model="account.email"
                  type="text"
                  name="email"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Телефон
              </label>
              <input
                  v-model="account.phone"
                  type="text"
                  name="phone"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Фамилия
              </label>
              <input
                  v-model="account.lastName"
                  type="text"
                  name="lastName"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/username12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Имя
              </label>
              <input
                  v-model="account.firstName"
                  type="text"
                  name="firstName"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Отчество
              </label>
              <input
                  v-model="account.patronymicName"
                  type="text"
                  name="patronymicName"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Пол
              </label>
              <Multiselect
                  v-model="account.gender"
                  placeholder="Выберите пол"
                  :options="genders"/>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Дата рождения
              </label>
              <VueDatePicker
                  v-model="account.birthdayAt"
                  locale="ru"
                  cancelText="Отмена"
                  selectText="Выбрать"
                  timezone="Europe/Moscow"
                  :enable-time-picker="false"
                  format="dd/MM/yyyy"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Школа
              </label>
              <Multiselect
                  v-model="account.school"
                  placeholder="Выберите школу ..."
                  :filter-results="false"
                  :min-chars="2"
                  :resolve-on-load="false"
                  :delay="0"
                  :canClear="false"
                  :searchable="true"
                  :noOptionsText="'Введите несколько символов'"
                  :noResultsText="'Школы не найдены'"
                  ref="schoolSelect"
                  :options="async function(query) {
                            return await fetchSchools(query)
                          }"/>
            </div>
          </div>
        </div>
        <hr>
        <div class="flex flex-wrap mt-6 mb-6">
          <div class="w-full lg:w-12/12">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Пароль
              </label>
              <span class="text-blueGray-400 text-sm">Если не нужно менять пароль оставьте поля пустыми</span>
              <input
                  v-model="account.password"
                  class="mb-6 mt-6 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :type="fieldTypes.password"
                  name="password"
                  value=""
                  @focus="handleType"
                  @blur="handleType"
                  autocomplete="off"
              />
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                Подтверждение пароля
              </label>
              <input
                  v-model="account.passwordConfirm"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  :type="fieldTypes.passwordConfirm"
                  name="passwordConfirm"
                  value=""
                  @focus="handleType"
                  @blur="handleType"
                  autocomplete="off"
              />
            </div>
          </div>
        </div>
        <hr>

        <div class="flex mx-auto mt-6 mb-20">
          <button type="submit"
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Сохранить
          </button>
          <button @click.prevent="cancel()"
                  class="bg-blueGray-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150">
            Отмена
          </button>
        </div>
      </form>

    </div>
  </div>
</template>
<script>

import AccountService from '@/services/account.service.js'
import schoolService from '@/services/api/school.service'
import Multiselect from '@vueform/multiselect'
import Toggle from '@vueform/toggle'
import moment from 'moment'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import Vue3TagsInput from 'vue3-tags-input'

export default {
  name: 'AccountEdit',
  components: {
    Toggle,
    Vue3TagsInput,
    Multiselect
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const errorMessage = ref(null)
    const schoolSelect = ref(null)

    const roles = ref([
      {id: 'ADMIN', text: 'Администратор'},
      {id: 'MODERATOR', text: 'Модератор'},
      {id: 'CONTROLLER', text: 'Контроллер'},
      {id: 'MENTOR', text: 'Наставник'},
      {id: 'MEMBER', text: 'Участник'}
    ])

    const genders = ref([
      {
        value: 'MALE',
        label: 'Мужской'
      },
      {
        value: 'FEMALE',
        label: 'Женский'
      }
    ])

    const account = ref({
      roles: roles.value.filter(r => r.id === 'MEMBER'),
      gender: 'MALE',
      disabled: false
    })

    const onDisabled = () => {
      account.value.disabled = !!account.value.disabled
    }

    const onAddRole = (role) => {
      account.value.roles.push(role)
    }

    const onRolesChanged = (roles) => {
      account.value.roles = roles
    }

    const cancel = async () => {
      await router.push(`/my/accounts`)
    }

    const fieldTypes = ref({
      password: 'text',
      passwordConfirm: 'text'
    })

    const handleType = (event) => {
      const {srcElement, type} = event
      const {name, value} = srcElement

      if (type === 'blur' && !value) {
        fieldTypes.value[name] = 'text'
      } else {
        fieldTypes.value[name] = 'password'
      }
    }

    const fetchSchools = async (query) => {
      const response = await schoolService.search({
        filter: query.toLowerCase(),
        size: 20
      })

      return response.data.content.map((school) => {
        return {
          value: school.id,
          label: school.name
        }
      })
    }

    const view = (id) => {
      AccountService.view(id)
          .then(response => {
            const school = response.data.school

            account.value = response.data
            account.value.roles = account.value.roles.map((role) => {
              return roles.value.find(r => r.id === role)
            })
            account.value.birthdayAt = moment(response.data.birthdayAt, 'DD/MM/YYYY').toDate()
            if (school) {
              account.value.school = school.id
            }

            if (school) {
              console.log(school)
              schoolSelect.value.select({
                value: school.id,
                label: school.name
              })
            }
          })
          .catch(error => console.error(error))
    }

    const update = async () => {
      const accountForSave = {...account.value}
      accountForSave.school = {
        id: account.value.school
      }
      accountForSave.roles = accountForSave.roles.map(r => r.id)

      AccountService.update(accountForSave)
          .then((response) => {
            router.push(`/my/accounts?after_edit=${response.data.id}`)
          })
          .catch((error) => {
            console.error(error)
          })
    }

    view(route.params.id)

    return {
      account,
      fieldTypes,
      errorMessage,
      roles,
      genders,
      schoolSelect,
      update,
      cancel,
      onDisabled,
      onAddRole,
      onRolesChanged,
      handleType,
      fetchSchools
    }
  }
}
</script>

<style src="@vueform/toggle/themes/default.css"></style>