import CrudService from '@/services/crud.service.js'

class SchoolService extends CrudService {

    constructor() {
        super('schools')
    }

}

export default new SchoolService()