import issueType from '@/chess/issueType'
import {rolesLabels} from '@/dataTypes.js'

const coalesce = (string) => {
    return string && string.length ? string : ''
}

const humanizeName = (user) => {
    if (!isNullOrEmpty(user.lastName) || !isNullOrEmpty(user.firstName) || !isNullOrEmpty(user.patronymicName)) {
        return `${coalesce(user.lastName)} ${coalesce(user.firstName)} ${coalesce(user.patronymicName)}`.trim()
    } else {
        return user.username
    }
}

const humanizeUser = (user) => {
    return {
        id: {
            title: 'ID:',
            value: user.id
        },
        login: {
            title: 'Логин:',
            value: user.username
        },
        name: {
            title: 'ФИО:',
            value: `${coalesce(user.lastName)} ${coalesce(user.firstName)} ${coalesce(user.patronymicName)}`.trim()
        },
        role: {
            title: 'Роли:',
            value: humanizeRoles(user.roles)
        }
    }
}

const humanizeRoles = (roles) => {
    if (roles && roles.length) {
        if (roles[0] && typeof roles[0] !== 'string') {
            let text = roles.map(o => o.label).join(', ').toLowerCase()
            return text[0].toUpperCase() + text.slice(1)
        } else {
            let text = roles.map(role => rolesLabels[role]).join(', ').toLowerCase()
            return text[0].toUpperCase() + text.slice(1)
        }
    }
    return ''
}

const convertRolesToOptions = (roles) => {
    if (roles) {
        return roles.map(role => {
            return {
                label: rolesLabels[role],
                value: role
            }
        })
    } else {
        return Object.keys(rolesLabels).map(value => {
            return {
                label: rolesLabels[value],
                value: value
            }
        })
    }
}

const fullFen = (position) => {
    return `${position} w KQkq - 0 1`
}

const isNullOrEmpty = (string) => {
    return !(string && string.length > 0)
}

const doIndexIssues = (issues) => {

    let index = 0
    issues
        .filter((issue) => {
            return issue.type === issueType.CHECKMATE_IN_1_MOVE.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.DOOMED_KING.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.LABYRINTH.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.GIVEAWAY.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.CHECKMATE_NETWORK.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index++
        })

    issues
        .filter((issue) => {
            return issue.type === issueType.TEST.type
        })
        .sort((issueA, issueB) => {
            return issueA.id - issueB.id
        })
        .forEach((issue) => {
            issue.index = index++
        })
}

export {
    humanizeName,
    humanizeRoles,
    humanizeUser,
    convertRolesToOptions,
    fullFen,
    isNullOrEmpty,
    doIndexIssues
}
