import authHeader from '@/services/api/auth-header.js'
import http from '@/services/api/http.js'

export default class CrudService {

    constructor(path) {
        this.path = path
    }

    create(entity) {
        return http.post(this.path, entity, {
            headers: authHeader()
        })
    }

    update(entity) {
        return http.put(this.path, entity, {
            headers: authHeader()
        })
    }

    delete(id) {
        return http.delete(`${this.path}/${id}`, {
            headers: authHeader()
        })
    }

    view(id) {
        return http.get(`${this.path}/${id}`, {
            headers: authHeader()
        })
    }

    search(query) {
        return http.get(this.path, {
            params: query,
            headers: authHeader()
        })
    }

}