<template>
  <div class="flex flex-col text-center w-full mb-20 m-6 p-6 rounded">
    <div class="flex justify-center items-stretch w-full">
      <div class="">
        <a style="height: 100%; width: 600px; margin-left: auto"
           class="block border-0 px-3 py-3 mb-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
          <label class="block uppercase text-blueGray-600 text-xs font-bold my-2 text-center">
            {{ issue.name }}
          </label>
          <p class="text-center">{{ issue.description }}</p>
        </a>
      </div>
    </div>


    <div class="flex justify-center items-stretch w-full mt-5 p-5">
      <div style="height: 100%; width: 90%;"
           class="block border-0 px-3 py-3 mb-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150">
        <label class="block uppercase text-blueGray-600 text-xs font-bold my-2 text-center">
          {{ issue.task.question }}
        </label>
        <div class="mb-5"></div>
        <div :key="id" v-for="(answr, id) in issue.task.answers"
             class="w-full mb-3 pl-3 cursor-pointer text-left" style="line-height: 2em;">
          <input :id="`answer-${id}`" name="answer" type="radio" :value="id" v-model="answer" class="cursor-pointer"
                 v-on:change="updateSolution">
          <label :for="`answer-${id}`" class="ml-3 cursor-pointer">{{ answr.text }}</label>
        </div>
      </div>
    </div>

    <div class="flex mx-auto overflow-hidden mt-6 mb-6">
      <button v-if="taskIndex > 0"
              class="bg-gray-200 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
              @click.prevent="prevIssue()"
              type="button">Предыдущая задача
      </button>
      <button v-if="taskIndex != maxIndex - 1"
              class="bg-gray-200 text-black active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none ml-3 mb-1 ease-linear transition-all duration-150"
              @click.prevent="nextIssue()"
              type="button">Следующая задача
      </button>
    </div>

  </div>
</template>

<script>

import {ref, watch} from 'vue'

export default {
  name: 'TestQuestion',
  props: {
    issue: {
      type: Object,
      required: true
    },
    taskIndex: {
      type: Number,
      required: true
    },
    maxIndex: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit}) {

    const answer = ref(null)

    watch(
        () => props.issue,
        () => {
          if (props.issue && props.issue.task && props.issue.task.solutions && props.issue.task.solutions.length) {
            answer.value = props.issue.task.solutions[0]
          } else {
            answer.value = null
          }
        },
        {immediate: true}
    )

    const updateSolution = () => {
      emit('addSolution', answer.value)
    }

    const prevIssue = () => {
      console.log(props.taskIndex - 1)
      emit('selectTask', props.taskIndex - 1)
    }

    const nextIssue = () => {
      console.log(props.taskIndex + 1)
      emit('selectTask', props.taskIndex + 1)
    }

    return {
      answer,
      updateSolution,
      prevIssue,
      nextIssue
    }
  }
}
</script>

<style>
</style>
