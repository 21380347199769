<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h1 class="font-semibold text-lg text-blueGray-700 py-3">База задач </h1>
        </div>
      </div>
    </div>
    <div class="w-full relative flex justify-start w-auto static block justify-start pb-4 px-4">
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
            <i class="fas fa-search"></i>
          </span>
          <input
              v-model="searchQuery"
              type="text"
              @keyup="searchByName"
              placeholder="Фильтр по задачам ..."
              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10"
          />
        </div>
      </form>
      <form class="flex flex-row flex-wrap items-center px-4">
        <div class="relative flex w-full flex-wrap items-stretch">
          <Multiselect
              :canClear="false"
              @change="onFilterByType"
              style="width: 250px"
              v-model="searchByType"
              :options="types"/>
        </div>
      </form>
      <button
          style="margin-left: auto"
          class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
          type="button"
          @click="$router.push(`/my/issues/new`)">
        <i class="fas fa-plus mr-2"></i> Новая задача
      </button>
    </div>
    <table-list :columns="columns" :actions="actions" :data="issues"/>
    <table-pagination v-if="totalPages > 0" :total="totalPages" @page-selected="loadPage"/>
  </div>
</template>

<script>
import issueType from '@/chess/issueType'
import TableList from '@/components/Tables/TableList.vue'
import TablePagination from '@/components/Tables/TablePagination'
import issueService from '@/services/issue.service'
import {nextTick, onMounted, ref, watchEffect} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import Multiselect from '@vueform/multiselect'

export default {
  name: 'IssuesList',
  components: {
    TableList,
    TablePagination,
    Multiselect
  },
  setup() {

    const route = useRoute()
    const router = useRouter()
    const issues = ref([])
    const totalPages = ref(0)
    const searchByType = ref(route.query.type || 'all')
    const types = [
      {label: 'Все', value: 'all'},
      {label: 'Мат в 1 ход', value: 'checkmate_in_1_move'},
      {label: 'Обреченный король', value: 'doomed_king'},
      {label: 'Лабиринт', value: 'labyrinth'},
      {label: 'Поддавки', value: 'giveaway'},
      {label: 'Матовая сеть', value: 'checkmate_network'},
      {label: 'Тест', value: 'test'}
    ]

    const formatType = (issue) => {
      return issueType.valueOf(issue.type).name
    }

    const formatTags = (issues) => {
      return issues.tags
    }

    const tableProps = {
      columns: [
        {
          title: 'ID',
          value: (issue) => {
            return issue.id
          }
        },
        {
          title: 'Тип',
          value: formatType
        },
        {
          title: 'Тэги',
          value: formatTags,
          array: true,
          badge: true
        },
      ],
      actions: {
        edit: {
          title: 'Редактировать',
          function: (issue) => {
            router.push(`/my/issues/${issue.id}/edit`)
          }
        },
        delete: {
          title: 'Удалить',
          function: (id, emit) => {
            emit('delete', id)
          }
        }
      }
    }

    onMounted(() => {
      watchEffect(() => {
        search(route.query)
      })
    })

    const search = (query) => {
      issueService.search(query)
          .then(response => {
            issues.value = response.data.content
            totalPages.value = response.data.totalPages
          })
          .catch(error => console.error(error))
    }

    const onDelete = (issue) => {
      if (confirm(`Удалить задачу ${issue.id}?`)) {
        issueService
            .delete(issue.id)
            .then((response) => {
              console.log(response)
            })
            .catch((error) => {
              console.error(error)
            })
      }
    }

    const buildQuery = () => {
      const query = {}
      const filter = searchQuery.value
      if (filter.length) {
        query.filter = filter
      }

      const type = searchByType.value
      if (type && type.length && type !== 'all') {
        query.type = type
      }
      console.log(query)
      return query
    }

    const onFilterByType = () => {
      nextTick(() => {
        router.push({
          path: '/my/issues',
          query: buildQuery()
        })
      })
    }

    let searchTimeout = null
    const searchQuery = ref(route.query.filter || '')
    const searchByName = async () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      searchTimeout = setTimeout(() => {
        router.push({
          path: '/my/issues',
          query: buildQuery()
        })
      }, 500)
    }
    const searchProps = {searchQuery, searchByName}

    const loadPage = (page) => {
      search({page: page})
    }
    const paginationProps = {
      totalPages, loadPage
    }

    search({})

    return {
      issues,
      searchByType,
      types,
      ...tableProps,
      ...searchProps,
      ...paginationProps,
      onDelete,
      onFilterByType
    }
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>