import authHeader from '@/services/api/auth-header'
import http from '@/services/api/http'
import CrudService from '@/services/crud.service.js'

class ContestService extends CrudService {
    
    constructor() {
        super('contests')
    }
    
    getMy() {
        return http.get('contests/my', {
            headers: authHeader()
        })
    }
    
    getByHash(hash) {
        return http.get(`contests/join/${hash}`, {
            headers: authHeader()
        })
    }
    
    start(id) {
        return http.post(`contests/${id}/start`, {}, {
            headers: authHeader()
        })
    }
    
    saveSolution(id, issue) {
        return http.post(`contests/${id}/solution`, issue, {
            headers: authHeader()
        })
    }
    
    finish(id) {
        return http.post(`contests/${id}/finish`, {}, {
            headers: authHeader()
        })
    }
    
    summarizing(id) {
        return http.post(`contests/${id}/summarizing`, {}, {
            headers: authHeader()
        })
    }
    
    getResults(id) {
        return http.get(`contests/${id}/results`, {
            headers: authHeader()
        })
    }
    
    getMemberResults(id) {
        return http.get(`contests/${id}/results/members`, {
            headers: authHeader()
        })
    }
    
    getTeamResults(id) {
        return http.get(`contests/${id}/results/teams`, {
            headers: authHeader()
        })
    }
    
    getReports(id) {
        return http.get(`contests/${id}/results/reports`, {
            headers: authHeader()
        })
    }
    
    getTrainingResults() {
        return http.get('contests/training/results', {
            headers: authHeader()
        })
    }
    
    getTrainingParams() {
        return http.get('contests/training/params', {
            headers: authHeader()
        })
    }
    
    setTrainingParams(params) {
        return http.put('contests/training/params', params, {
            headers: authHeader()
        })
    }
    
    getTrainingIssues() {
        return http.get('contests/training/issues', {
            headers: authHeader()
        })
    }
    
    addTrainingIssue(issueId) {
        return http.put(`contests/training/issues/${issueId}`, {}, {
            headers: authHeader()
        })
    }
    
    removeTrainingIssue(issueId) {
        return http.delete(`contests/training/issues/${issueId}`, {
            headers: authHeader()
        })
    }
    
    getCurrentTraining() {
        return http.get('contests/training/current', {
            headers: authHeader()
        })
    }
    
    startTraining() {
        return http.post('contests/training/start', {}, {
            headers: authHeader()
        })
    }
    
    finishTraining(solutions) {
        return http.post('contests/training/finish', solutions, {
            headers: authHeader()
        })
    }
    
    joinMember(hash, member) {
        return http.post(`contests/join/${hash}/member`, member, {
            headers: authHeader()
        })
    }
    
}

export default new ContestService()
