<template>
  <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">Просмотр пользователя</h6>
        <div class="text-center flex justify-end">
          <button
              class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-4 ease-linear transition-all duration-150"
              type="button"
              @click="$router.push(`/my/contests/${readContest.id.value}/edit`)"
          >
            Редактировать
          </button>
        </div>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <div class="flex flex-wrap mt-10 mb-6">
        <List :data="readContest"/>
      </div>
    </div>
  </div>
</template>
<script>

import {onMounted, ref} from "vue";
import ContestService from "@/services/contest.service.js";
import List from "@/components/Cards/List.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'ContestView',
  components: {List},
  props: ['id'],
  setup() {

    const contest = ref({})
    const readContest = ref({})

    onMounted(async () => {
      // const res = await AccountService.view(props.id)
      const res = await ContestService.view(434)

      contest.value = res.data.content[0];
      readContest.value = {
        id: {
          title: 'ID:',
          value: contest.value.id
        },
        name: {
          title: 'Название:',
          value: contest.value.name
        },
      }

    });

    return {
      readContest
    }
  }
};
</script>
